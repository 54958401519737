@import "../../assets/styles/media";

.header-wrap {
	position: relative;

	.loader {
		position: absolute;
		width: 100%;
		height: 100vh;
		opacity: 0;
		visibility: hidden;
		transition: 1s 2s;
		background: #fff;
		z-index: 100;
	}

	.header {
		opacity: 1;
		transition: 1s 2s;
	}

	&.loading {
		.loader {
			opacity: 1;
			visibility: visible;
			transition: 1s 2s;
		}

		.header {
			opacity: 0;
			transition: 1s 2s;
		}
	}
}

.header {
	min-height: 100vh;
	background: url("./images/headerBg.jpg"), linear-gradient(180deg, #a6e791 0%, #1d8287 88.02%, #008f96 100%);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-top: 60px;
	padding-bottom: 60px;

	@include desktop-down {
		background-position: right 40% center;
	}

	@include mobile-down {
		min-height: 800px;
		background-image: url("./images/headerBgMobile.jpg");
		background-position: center top;
		padding-top: 30px;
		display: flex;
		position: relative;

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 139px;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.79) 65.62%, #fff 100%);
		}
	}

	.content {
		@include mobile-down {
			display: flex;
			flex-direction: column;
			flex: auto;
			position: relative;
			z-index: 10;
		}
	}

	.main-header {
		:global {
			.sub-title {
				font-size: 34px;
				color: var(--text-color-primary);
				line-height: 1.36;
				margin-top: 20px;

				@include mobile-down {
					font-size: 22px;
					line-height: 1.5;
					margin-top: 15px;
				}
			}
		}
	}

	.logo-auth-wrap {
		max-width: 634px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 30px;
	}

	.auth-link {
		a {
			font-size: 14px;
			font-weight: 700;
			gap: 5px;
		}
	}

	.row-2 {
		margin-top: 123px;
		display: flex;
		justify-content: space-between;

		@include mobile-down {
			margin-top: 40px;
			flex: auto;
		}

		.col-1 {
			width: 100%;

			@include tablet-up {
				max-width: 600px;
			}

			@include mobile-down {
				display: flex;
				flex-direction: column;
			}
		}

		.col-2 {
			max-width: 301px;
			margin-right: 104px;
		}
	}

	.btn-sing-wrap {
		margin-top: 50px;

		@include mobile-down {
			margin-top: 35px;
			text-align: center;
		}
	}

	.market-btns-wrap {
		@include mobile-down {
			margin-top: auto;
			justify-content: center;
		}
	}

	.market-btns {
		margin-top: 66px;

		@include mobile-down {
			margin-top: 0;
		}

		img {
			height: 34px;
		}
	}
}
