@import "../../../assets/styles/media";

.travel-advice {
	box-sizing: border-box;

	.header {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		@include mobile-down {
			flex-direction: column-reverse;
			gap: 10px;
		}

		.title {
			// font-size: 26px;
			// font-size: 32px;
			line-height: 1.44;

			@include mobile-down {
				// font-size: 22px;
				line-height: 1.28;
			}
		}
	}

	.travel-advice-flag {
		display: inline-block;
		position: relative;
		border: 2px solid #fff;
		border-radius: 4px;
		filter: drop-shadow(0px 5px 10px rgba(0, 24, 32, 0.07));

		&:after {
			content: "";
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02));
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.flag-icon {
		width: auto;
		height: 28px;
	}

	.body {
		padding: 20px;
		margin-top: 24px;
	}

	.btn-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
	}
}
