@import "../../../../assets/styles/media";

.disclaimer-wrap-inner {
	line-height: 1.4;

	.accordion-item:not(:last-child) {
		margin-bottom: 20px;

		@include mobile-down {
			margin-bottom: 12px;
		}
	}

	h3 {
		text-align: left;
	}

	.list {
		padding-left: 14px;
		list-style: decimal;
	}

	.item {
		font-size: 13px;
		color: var(--text-color-secondary);
		margin-bottom: 12px;
	}
}
