.comparison-info {
	position: absolute;
	left: 40%;
	top: 20%;
	background: rgba(248, 249, 249, 0.7);
	border: 1px solid rgba(218, 34, 47, 1);
	border-radius: 4px;
	min-height: 90px;
	width: 160px;
	padding: 6px;
	opacity: 0;
	transform: translateY(-10px);
	transition:
		opacity 2s ease,
		transform 1s ease;

	&.visible {
		opacity: 1;
		transform: translateY(0);
	}

	.title {
		color: var(---blueDark);
		display: flex;
		gap: 5px;

		.image {
			display: flex;
			align-items: center;
			font-size: 18px;
		}

		.text {
			font-size: 13px;
			font-weight: 700;
		}
	}
	.body {
		font-size: 13px;
		font-weight: 400;
		color: var(---blueDark);
	}
}
