@import "../../assets/styles/media";

.step-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 20px;

	@include mobile-down {
		max-width: 440px;
		width: 100%;
		justify-content: flex-start;
		margin-left: 80px;
	}

	.step-header-item {
		display: flex;
		align-items: center;
		color: var(--gray);
		gap: 5px;

		.step-header-item-hide-text {
			@include mobile-down {
				display: none;
			}
		}

		.step-header-number {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 40px;
			height: 40px;
			background: var(--white);
			border: 1px solid var(--grayLight);
			border-radius: 48px;
			margin-left: 5px;
			font-size: 20px;
		}

		.step-header-selected-number {
			background: var(--button-tab-background-primary-active);
			color: var(--white);
		}
	}
}
