@import "../../assets/styles/media";

body > iframe[style*="2147483647"] {
	display: none;
}

.travel-risk-report {
	background: var(--background-color-primary);
	color: var(--text-color-primary);
	width: 100%;

	@include phablet-down {
		width: 100%;
	}

	.section:not(.header-wrap) {
		margin-top: 30px;
	}

	:global {
		.box-shadow {
			box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07), 0px 5px 10px rgba(0, 24, 32, 0.07);
		}

		.data-layer-container {
			position: absolute;
			top: 5px;
			right: 10px;
		}
	}

	.content {
		max-width: 1060px;
		min-height: auto;
		padding: 24px 0 16px;

		&-without-padding {
			padding: 0;

			&-x {
				padding-left: 0;
				padding-right: 0;
			}

			&-y {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	> .content {
		padding-top: 0;

		@include phablet-down {
			align-items: center;

			> section {
				width: 100%;
			}
		}
	}

	.report-wrap {
		opacity: 0;

		> :first-of-type {
			margin-top: 0;
		}
	}

	.report-wrap-done-enter {
		opacity: 1;
		transition: 0.5s;
	}

	.violent-crime-risk-container {
		margin-top: 16px;
		display: flex;
		justify-content: center;
	}

	.risk-scores-estimates-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 9px;

		@include tablet-down {
			justify-content: center;
			gap: 30px;
		}
	}

	.relative-risk-weighting-container {
		.row-1 {
			display: flex;
			justify-content: space-between;
			gap: 30px;

			@include tablet-down {
				flex-direction: column;
				align-items: center;
			}

			.col {
				max-width: 495px;
				width: 100%;
			}
		}
	}

	.geographic-risk-rating {
		.row {
			display: flex;
			justify-content: space-between;
			gap: 32px;

			@include tablet-down {
				flex-direction: column;
				align-items: center;
			}
		}

		.col {
			max-width: 494px;
			width: 100%;
		}
	}

	.geographic-risk-trands {
		.row {
			display: flex;
			justify-content: space-between;
			gap: 32px;

			@include tablet-down {
				flex-direction: column;
				align-items: center;
			}
		}

		.col {
			max-width: 494px;
			width: 100%;
		}
	}

	.statistics-map {
		.row {
			display: flex;
			justify-content: space-between;
			gap: 32px;

			@include mobile-down {
				flex-direction: column;
				align-items: center;
			}
		}

		.col-1 {
			width: 100%;
		}

		.col-2 {
			width: 100%;

			@include tablet-up {
				max-width: 256px;
			}
		}
	}

	.nav-mobile-container {
		position: fixed;
		width: 100%;
		bottom: -70px;
		left: 0;
		background: var(--background-color-secondary);
		box-shadow: 0px -5px 10px 0px rgba(0, 24, 32, 0.07);
		opacity: 0.3;
		visibility: hidden;
		transition: 0.5s;
		z-index: 10;

		&.active {
			opacity: 1;
			visibility: visible;
			bottom: -2px;
		}
	}
}
