@import "../../../assets/styles/media";

.header-content-wrap {
	.content {
		padding-bottom: 0;
	}

	.row {
		display: flex;

		&.row-1 {
			justify-content: space-between;

			.col-2 {
				.btn-wrap {
					display: flex;
					align-items: center;
					gap: 16px;

					.btn {
						display: flex;
						justify-content: center;
						align-items: center;
						color: var(--blueMediumDark);
						width: 40px;
						height: 40px;
						background: var(--grayLight);
						border-radius: 50%;
						cursor: pointer;
						transition: 0.3s;
					}

					.btn-to-img {
						&:hover {
							color: var(--violet);
						}

						&.btn-loading {
							color: rgba(51, 75, 93, 0.3);
							animation: pulse 1s infinite;
							pointer-events: none;
						}

						@keyframes pulse {
							0% {
								transform: scale(1);
							}
							50% {
								transform: scale(1.2);
							}
							100% {
								transform: scale(1);
							}
						}
					}
				}
			}
		}
	}
}
