@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

.footer-content {
	.footer-text {
		font-size: 16px;
		color: $blueDark;
		line-height: 1.4;
	}
}
