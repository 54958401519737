@import "../../../assets/styles/media";

.list-wrapper {
    display: flex;
    align-items: center;
    max-width: 310px;
    margin-left: 21px;
    :global {
        animation: preloader-pulse 1s infinite;
    }

    @include tablet-down {
        margin-top: 18px;
    }
}

.block {
    background-color: #d9d9d9;
	opacity: 0.5;
	width: 293px;
	height: 96px;
	box-shadow: none;
	border-radius: 10px;
	padding: 8px 20px;

    :global {
        animation: preloader-pulse 1s infinite;
    }
    @include tablet-down {
        width: 100%;
    }
}
