.form-header {
	max-width: 440px;
	width: 100%;
	margin: 30px auto 13px;

	.description {
		color: var(--gray);
		margin-top: 13px;
	}
}
