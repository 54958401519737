@import "../../assets/styles/media";
@import "../../assets/styles/colorConstants";

.profile-wrap {

	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: column;
	color: var(--blueDark);

	.hide {
		display: none !important;
	}

	.header {
		padding: 20px;
		font-size: 40px;
	}

	.title {
		font-size: 32px;
		line-height: 136%;
	}

	.sub-title {
		font-size: 16px;
		line-height: 140%;
		color: var(--textGray);
	}

	.body {
		width: 100%;
	}

	.form-wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
		gap: 10px;
		margin: 0 20px;
		background: #FFFFFF;
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		border-radius: 10px;

		.title-wrap {
			display: flex;
			width: 100%;
			justify-content: space-between;

			.title-button {
				color: var(--newLink);
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 5px;
			}
		}

		.field-wrap-location {
			:global {
				.MuiFormControl-root  {
					border-bottom: 2px solid var(--grayLight);
				}
			}
			margin-bottom: 20px;

			[class*='-control'] {
				background-color: unset;
			}
		}

		.field-label {
			color: var(--gray) !important;
			font-size: 1.2rem;
		}

		.field-inline {
			display: flex;
			padding-bottom: 50px;
			gap: 30px;

			.field-wrap-age {
				width: 50%;
			}

			.field-wrap-gender {
				width: 50%;
			}

			button {
				max-width: 210px;
			}
		}

		.field-inner {
			:global {
				.MuiInput-root {
					width: 100%;
				}
			}

			.field-wrap {
				position: relative;

				.field-inner:hover {
					border-color: transparent;

					.btn {
						opacity: 1;
					}

					.btn-add-disabled {
						opacity: 0.3;
						cursor: auto;
					}
				}

				.field-error,
				.field-help-text {
					position: absolute;
					top: 50px;
					font-size: 11px;
					line-height: 1;
					text-align: left;
				}

				.field-error {
					color: red;
				}

				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active {
					box-shadow: 0 0 0 30px #fff inset !important;
					-webkit-box-shadow: 0 0 0 30px #fff inset !important;
					-webkit-text-fill-color: var(--blueDark) !important;
				}

				input:-internal-autofill-selected {
					background-color: white !important;
				}

				::placeholder {
					color: var(--grayLight) !important;
					opacity: 1 !important;
				}

				::-webkit-input-placeholder {
					color: var(--grayLight) !important;
					opacity: 1 !important;
				}

				::-moz-placeholder {
					color: var(--grayLight) !important;
					opacity: 1 !important;
				}

				input:-moz-placeholder {
					color: var(--grayLight) !important;
					opacity: 1 !important;
				}

				input {
					color: var(--text-color-primary) !important;
				}

				input:-webkit-autofill,
				input:-webkit-autofill:hover,
				input:-webkit-autofill:focus,
				input:-webkit-autofill:active {
					box-shadow: 0 0 0 30px #fff inset !important;
					-webkit-box-shadow: 0 0 0 30px #fff inset !important;
					-webkit-text-fill-color: var(--text-color-primary) !important;
				}

				input:-internal-autofill-selected {
					background-color: var(--background-color-secondary) !important;
				}
		}
	}

	.avatar-wrap {
		display: flex;
		align-items: center;
		padding: 10px 0;
		gap: 10px;

		div {
			margin: 0;
		}

		.avatar-upload-btn {
			span {
				text-transform: none;
			}
		}

		.avatar-delete-btn {
			font-size: 16px;
			color: #C10000;
			cursor: pointer;
		}

		.avatar {
			background-size: cover;
			background-position: top;
			width: 80px;
			height: 80px;
		}
	}
  }

	:global {
		.MuiInput-root {
			position: relative;
			width: 100%;
			color: var(--gray);
			margin-bottom: 20px;

			.MuiSelect-select {
				width: 100%;
				text-align: start;
			}
		}

		.MuiInput-input, .MuiFormLabel-root {
			color: var(--gray) !important;
		}
	}
}
