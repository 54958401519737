:global {
	.risk-safety-summary-container {
		:local {
			.item-wrap {
				padding-bottom: 12px;
				border-bottom: 1px solid var(--border-color-secondary);
				padding-left: 38px;

				.description {
					max-width: 632px;
					color: #000;
				}

				.name-wrap {
					display: flex;
					align-items: center;
					gap: 14px;
				}

				.name-icon {
					width: 24px;
					height: 24px;
					background: var(--icon-background-color-one);
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					color: var(--icon-color-four);
					margin-left: -38px;
					box-sizing: border-box;

					&.emergency {
						font-size: 22px;
					}

					&.encrypted {
						background: var(--icon-background-color-two);
						font-size: 20px;
						padding-left: 0.5px;
					}

					&.dangerous {
						background: var(--icon-background-color-three);
						font-size: 22px;
						padding-left: 1px;
					}
				}

				.name {
					font-weight: 700;
					line-height: 100%;
					letter-spacing: 0.32px;
					text-transform: uppercase;
				}

				.content-wrap {
					display: flex;
					flex-direction: column;
					gap: 8px;
					margin-top: 16px;

					@media only screen and (max-width: 767px) {
						gap: 15px;
					}
				}

				.row {
					display: flex;
					gap: 10px;

					@media only screen and (max-width: 767px) {
						flex-direction: column;
						gap: 3px;
					}
				}

				.label {
					min-width: 140px;
					color: var(--text-link-color-primary);
					font-weight: 700;
				}
			}
		}

		.text-placeholder-wrap {
			:local {
				.name-wrap,
				.name,
				.value {
					color: transparent;
					text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
					-webkit-user-select: none;
					-moz-user-select: none;
					user-select: none;
				}
			}
		}
	}
}
