@import "../assets/styles/colors";

.select-with-buttons {
	display: flex;
	flex-direction: column;
	align-items: center;

	li {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 297px;
		min-height: 50px;
		background: #ffffff;
		border: 1px solid #7d8c97;
		box-sizing: border-box;
		border-radius: 4px;
		margin-bottom: 10px;
		color: gray;
		position: relative;
		cursor: pointer;

		&:last-of-type {
			margin-bottom: 0;
		}

		.check-icon {
			display: none;
			position: absolute;
			top: 50%;
			right: 1.6rem;
			transform: translateY(-50%);
			font-size: 2.5rem;
			color: $green;
		}

		&.selected {
			box-shadow: 0px 7px 20px rgba(0, 26, 33, 0.15);
			border: 0;
			color: $blueDark;

			.check-icon {
				display: block;
			}
		}
	}
}
