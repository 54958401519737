@import "../../assets/styles/media";

.stats-container {
	color: var(--blueDark);
	line-height: 1.3;
	margin-top: 50px;
}

.filters {
	display: flex;
	gap: 10px;
	margin: 20px 0;
}

.filter-item {
	display: inline-block;
	padding: 5px 10px 2px;
	font-size: 14px;
}

.option-wrap {
	display: flex;
	align-items: center;
}

.date-range {
	font-size: 11px;
	line-height: 1.2;
}

.filter-item-calendar {
	min-width: 225px;
}

.calendar-icon {
	font-size: 16px;
	color: var(--gray);
}

.option {
	font-weight: 700;
}

.cards-data {
	display: flex;
	gap: 20px;

	@include phablet-down {
		flex-direction: column;
	}
}

.card-data-item {
	width: 190px;
	height: 190px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
}

.widget-title {
	font-size: 13px;
}

.values-wrap {
	justify-self: center;
}

.value {
	font-size: 50px;
	font-weight: 700;
	text-align: center;
}

.percent {
	color: var(--blueMediumDark);
	line-height: 1;
	text-align: center;
}

.percent-minus {
	color: var(--crime);

	.percent-arrow {
		transform: rotate(180deg);
	}
}

.percent-plus {
	color: var(--green);
}

.percent-arrow {
	font-size: 14px;
}

.footer {
	font-size: 8px;
	text-align: center;
}
