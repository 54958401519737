@import "../../assets/styles/colors";
@import "../../assets/styles/media";

.view-limit {
	display: flex;
	flex: 1 1;

	.view-limit-content {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;

		.block-icon {
			margin-top: 3.2rem;
		}

		.block-text {
			text-align: center;
			margin: 2rem 0 0;

			.advice {
				color: $blueMediumDark;
				padding-top: 1rem;

				.link-to-pro {
					text-decoration: underline;
				}
			}
		}

		.previous-reports-wrap {
			margin-top: 2rem;
			text-align: center;

			.previous-reports-title {
				color: $gray;
				text-align: center;
			}

			.previous-reports-data {
				max-width: 55rem;
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				margin-top: 2rem;
			}

			.previous-report-item {
				margin-bottom: 1rem;

				@include mobile-down {
					max-width: 25rem;
				}

				@include tablet-up {
					max-width: 30rem;
				}

				@include bigdesktop-up {
					max-width: 50rem;
				}

				a {
					// max-width: 17.2rem;
					display: flex;
					align-items: center;
					border: 0.1rem solid $grayLight;
					border-radius: 1rem;
					padding: 1rem 1.2rem;
					margin: 0 0.5rem;
					transition: 0.25s;

					&:hover {
						border-color: $violet;
						box-shadow:
							0px 2px 4px -1px rgba(0, 0, 0, 0.2),
							0px 4px 5px 0px rgba(0, 0, 0, 0.14),
							0px 1px 10px 0px rgba(0, 0, 0, 0.12);
					}

					.previous-report-item-name {
						color: $blueMediumDark;
					}
				}

				.location-icon {
					width: 1.6rem;
					min-width: 1.6rem;
					margin-right: 1rem;

					path {
						stroke: $gray;
					}
				}
			}
		}

		.btn-wrap {
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			margin-top: auto;
			margin-bottom: 4rem;
			padding-top: 1rem;

			button {
				min-width: 27.1rem;
				padding-left: 1.4rem;
				padding-right: 1.4rem;
				margin: 1rem;

				@include mobile-down {
					padding-left: 1.4rem;
					padding-right: 1.4rem;
				}
			}

			.btn-icon-arrow {
				margin-right: 5px;
			}
		}
	}
}
