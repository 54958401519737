.summary-tab {
	.header {
		margin-bottom: 12px;
		font-size: 26px;
		font-weight: 400;
		line-height: 1.44;
	}

	.body-section {
		line-height: 1.6;
		font-size: 16px;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.text-placeholder {
		color: transparent;
		text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}
}
