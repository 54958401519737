:global {
	.risk-safety-summary-container {
		:local {
			.safest-places {
				margin-top: 16px;
				padding-bottom: 16px;

				.contacts-wrap {
					margin-top: 12px;
				}
			}
		}
	}
}
