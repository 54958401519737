.personal-academy-tips {
	height: 100%;
}

.block-img {
	display: flex;
	justify-content: center;
	max-height: 190px;
	overflow-y: hidden;
	max-width: 100%;
	position: relative;

	img {
		max-width: 392px;
		width: 100%;
		height: 190px;
	}

	.placeholder-img {
		max-width: 190px;
	}
}

.loader-container {
	max-width: 392px;
	width: 100%;
	height: 190px;
	background-color: #f0f0f0;
	overflow: hidden;
	position: absolute;
	opacity: 0;
	transition: opacity 0.3s;
}

.loader-active {
	.loader-container {
		opacity: 1;
	}

	.loading-bar {
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, transparent 25%, #ddd 50%, transparent 75%);
		background-size: 200% 100%;
		animation: loading 1.3s infinite;
	}
}

@keyframes loading {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(100%);
	}
}

.block-text {
	margin-top: 16px;
	text-align: left;
}

.slide-content {
	height: 343px;
	padding-left: 4px;
	padding-right: 4px;
	overflow-y: auto;
}

.title {
	display: flex;
	justify-content: space-between;
	height: 100%;
	font-size: 20px;
	line-height: 23px;

	.read-icon {
		font-size: 32px;
		line-height: 32px;
		color: var(--alarm-normal-color);
		opacity: 0;
		width: 0;
		overflow: hidden;
		transition: 0.25s;
	}
}

.description {
	font-size: 13px;
	line-height: 18.2px;
	min-height: 55px;
	margin-top: 12px;
	overflow-y: auto;
}

// TODO: In progress. Remove after testing.

// .height-with-link {
// 	max-height: 57px;
// }

// .height-without-link {
// 	max-height: 90px;
// }

.btn-got-it {
	background: transparent;
}

.check-icon {
	font-size: 14px;
}

.slide-not-read {
	.block-img {
		opacity: 1;
		transition: 0.25s;
	}

	.title,
	.description {
		color: var(--text-color-primary);
		transition: 0.25s;
	}
}

.slide-read {
	.block-img {
		opacity: 0.5;
	}

	.title,
	.description {
		color: var(--text-color-secondary);
	}

	.read-icon {
		width: 32px;
		opacity: 1;
	}
}
