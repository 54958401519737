@import "../../assets/styles/media";

.profile-account-management-wrap {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: column;
	color: var(--blueDark);

	.block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
		gap: 10px;
		margin: 10px 20px;
		background: #FFFFFF;
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		border-radius: 10px;

		.block-title {
			font-size: 32px;
			line-height: 136%;
		}

		.block-sub-title {
			font-size: 16px;
			line-height: 140%;
			color: var(--textGray);
			max-width: 560px;
		}

		.block-body {
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			padding-top: 10px;

			button {
				height: 38px;

				span {
					text-transform: none;
				}
			}
		}

		.block-body-la {
			flex-direction: column;

			.block-body-la-row {
				justify-content: space-between;
				display: flex;
				padding: 10px 0;
    		align-items: center;

				.block-body-la-cell {
					flex: 1;
					display: flex;
					align-items: center;

					.block-body-la-row-icon {
						padding-right: 10px;
					}

					&:last-of-type {
						flex: 0;
					}
				}

				button {
					width: 120px;
				}
			}
		}

		.email {
			font-weight: 700;
			font-size: 16px;
			line-height: 140%;
			color: var(--blueDark);
		}
	}

	.bottom-block {
		padding: 20px;


		button {
			margin: 0 10px;

			span {
				text-transform: none;
			}
		}
	}

	.bottom-block-delete-account {
		color: #C10000;
	}
}
