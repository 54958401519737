@import "../../assets/styles/media";

.travel-risk-ratings {
	display: flex;
	flex-direction: column;

	.header {
		max-width: 1083px;
	}

	:global {
		.slick-slider {
			@include mobile-down {
				height: 100vw;
			}

			@include narrower-than(600) {
				height: 112vw;
			}

			@include narrower-than(440) {
				height: 127vw;
			}

			@include narrower-than(361) {
				height: 129vw;
			}
		}

		.slick-slide {
			height: 80%;
			display: flex;
			align-items: center;
		}
	}

	.row-1 {
		min-height: 560px;
		background: var(--background-color-secondary);
		padding-top: 150px;

		@include desktop-down {
			min-height: 406px;
			padding-top: 50px;
		}

		@include mobile-down {
			min-height: auto;
			order: 2;
		}
	}

	.row-2 {
		min-height: 370px;
		background: var(--background-color-primary);

		@include desktop-down {
			min-height: 263px;
		}

		@include tablet-down {
			min-height: auto;
		}

		@include mobile-down {
			background: var(--background-color-secondary);
			order: 3;
			padding: 20px 0 50px;
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;

			@include mobile-down {
				padding: 0;
			}
		}

		.img-wrap {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 18px;

			@include desktop-down {
				justify-content: space-around;
			}

			@include mobile-down {
				width: 100%;
				margin-left: -20px;
				margin-right: -20px;
			}
		}

		img {
			max-width: 345px;

			@include desktop-down {
				width: calc(100% / 3 - 18px);
			}

			@include tablet-up {
				margin-top: -124px;
			}

			@include mobile-down {
				max-width: none;
				width: 100%;
			}
		}
	}

	.row-3 {
		min-height: auto;

		@include tablet-up {
			padding-bottom: 50px;
		}

		@include mobile-down {
			background: var(--background-color-secondary);
			order: 1;
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	.btn-wrap {
		display: flex;
		justify-content: center;
		margin-top: 57px;
		width: 222px;

		@include mobile-down {
			margin-top: 50px;
		}
	}
}
