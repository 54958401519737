.risk-slider {
	position: relative;
	margin-top: 44px;

	&:before {
		content: "";
		width: 1px;
		height: 88px;
		background: var(--background-color-secondary);
		opacity: 0.1;
		position: absolute;
		left: 0;
		top: calc(50% - 88px / 2);
	}

	.risk-value-label {
		position: absolute;
		top: -30px;
		font-size: 26px;
		color: var(--text-color-primary);
		padding-left: 10px;

		span {
			font-size: 13px;
			vertical-align: baseline;
			padding-left: 3px;
		}

		.risk-label {
			margin-top: 5px;

			span {
				padding-left: 0;
			}
		}
	}
}
