.emergency {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.title {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		margin-top: 5px;
	}

	.subtitle {
		font-weight: 400;
		font-size: 18px;
		line-height: 1.44;
		margin-bottom: 5px;
	}

	.contactType {
		font-weight: 700;
	}

	.contactSection {
		padding: 0;
		border-bottom: 1px solid var(--border-color-secondary);
	}

	.contactRow {
		padding-bottom: 5px;
	}
}
