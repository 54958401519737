@import "../../../../../assets/styles/media";

.bar-chart-container {
	width: 100%;
	position: relative;

	.header-container {
		width: 100%;

		.header {
			margin-bottom: 20px;
			display: flex;
			justify-content: space-between;
			gap: 10px;

			@include mobile-down {
				flex-direction: column;
			}

			.title {
				h5 {
					font-size: 16px;
					font-weight: 700;
					color: var(--text-color-primary);
				}

				p {
					font-size: 13px;
					font-weight: 400;
					color: var(--text-color-secondary);
				}
			}
		}
	}

	.bar-chart {
		width: 100%;
		height: 220px;
		position: relative;
	}
}
