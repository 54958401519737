@import "../assets/styles/colors";

.bg-black-09 {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.9);
	z-index: 10;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-container {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	padding: 20px;
}

.loader-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	:global {
		.small {
			width: 56px;
			height: 56px;
		}
	}
}

.loader-icon {
	width: 137px;
	height: 137px;
	:global {
		animation: rotating 1.4s linear infinite;
	}
}

.loader-text {
	color: $gray;
}
