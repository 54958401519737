@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

$colorTextGray: #5b6a76;

.risk-scores-container-blured {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	max-width: 320px;
	width: 100%;
	background: $white;
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	background-size: cover;

	@include mobile-down {
		max-width: 495px;
	}

	& .blured-container {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(248, 249, 249, 0.8) 100%);

		&:hover {
			background: linear-gradient(180deg, rgba(248, 249, 249, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%);

			& .main-content .img-wrapper .lock-icon path {
				fill: #4b8c8c;
			}
		}
	}

	& .content-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		text-align: center;
		padding: 20px;
		box-sizing: border-box;
		position: relative;
	}

	.title {
		font-size: 20px;
		color: $blueDark;
		font-weight: 400;
		line-height: 1.15;
		text-align: left;
		margin: 0;
		padding: 0;
	}

	.main-content {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-top: 50px;
		// padding-bottom: 45px;

		.description {
			font-size: 16px;
			font-weight: 600;
			line-height: 22.4px;
			color: $colorTextGray;
		}

		.img-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 200px;
			height: 200px;
			padding-bottom: 25px;
			background: radial-gradient(50% 50% at 50% 50%, rgba(126, 242, 243, 0.4) 0%, rgba(126, 242, 243, 0) 100%);
		}

		.buttom-wrapper {
			width: 100%;
			margin-top: 25px;
		}

		.button-buy {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 300px;
			height: 44px;
			font-style: normal;
			font-weight: 700;
			font-size: 15px;
			line-height: 16px;
			background-color: white;
			color: $green;
			border: 2px solid $green;
			border-radius: 2px;
			cursor: pointer;
			position: absolute;
			bottom: 0;
			z-index: 2;
			transition: 0.3s;

			&:hover {
				color: white;
				background-color: $green;
				border: none;
			}
		}
	}
}
