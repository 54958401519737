//##hearst
@import "../../../assets/styles/media";
// @import "../../../assets/media";

.risk-highlights-indicator-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 10px;

	@include desktop-down {
		justify-content: center;

		& .risk-highlights-indicator-wrapper {
			margin-top: 0px;
		}
	}

	@include tablet-down {
		gap: 30px;
	}

	@include mobile-down {
		flex-direction: column;
		align-items: center;
		// gap: 12px;
	}
}
