@import "../../../assets/styles/media";

.preloader-report {
	width: 100%;
	height: 100%;

	.section:not(.header-wrap) {
		margin-top: 30px;
	}

	h3 {
		font-size: 26px;
		line-height: 1.44;

		@include mobile-down {
			font-size: 22px;
			line-height: 1.28;
		}
	}

	h4 {
		font-size: 20px;
		line-height: normal;
	}

	:global {
		.sub-title {
			font-size: 16px;
			line-height: 1.44;
			color: var(--text-color-primary);
		}
	}

	.content {
		max-width: 1060px;
		min-height: auto;
		padding: 24px 0 16px;

		.preloader-section {
			padding: 24px 20px 45px;
			background: var(--background-color-secondary);
			border-radius: 10px;
			box-shadow: none;
		}

		.preloader-text-block {
			color: transparent;
			background-color: #d9d9d9;
			border-radius: 2px;
			opacity: 0.5;
			user-select: none;
			:global {
				animation: preloader-pulse 1s infinite;
			}
		}

		.preloader-map {
			height: 500px;
		}
	}

	> .content {
		padding-top: 0;
	}
}
