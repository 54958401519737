@import "../../assets/styles/colors";

.page-footer-container {
	width: 100%;
	font-size: 1.3rem;
	margin-top: 3rem;

	&.footer-mob {
		display: none;
		text-align: center;
	}

	hr {
		border-top: 1px solid $blueDark;
		opacity: 0.1;
	}

	.footer-content {
		display: flex;
		justify-content: space-between;
		margin: 2.2rem 0;
	}

	.copyright {
		color: $blueDark;
	}

	.link-about {
		color: $gray;
		cursor: pointer;
	}

	.footer-block-right {
		text-align: right;

		a {
			margin-left: 2rem;
		}
	}

	@media only screen and (max-width: 1399px) {
		margin-top: 0;

		&.footer-desk,
		hr {
			display: none;
		}

		&.footer-mob {
			display: block;
		}
	}

	@media only screen and (max-width: 767px) {
		.footer-content {
			flex-direction: column;
			padding: 0 2rem 4rem;

			.footer-block-left,
			.footer-block-right {
				text-align: left;

				a {
					display: block;
					margin-left: 0;
				}
			}
		}
	}
}
