.wrapper {
    max-width: 310px;

    :global {
        animation: preloader-pulse 1s infinite;
    }
}

.graph {
    width: 100%;
}