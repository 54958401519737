@import "../../assets/styles/media";

.page-report {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	min-height: 100vh;

	@include mobile-down {
		margin: 0 auto;
	}

	h3 {
		font-size: 26px;
		line-height: 1.44;

		@include mobile-down {
			font-size: 22px;
			line-height: 1.28;
		}
	}

	h4 {
		font-size: 20px;
		line-height: normal;
	}

	:global {
		.sub-title {
			font-size: 16px;
			line-height: 1.44;
			color: var(--text-color-primary);
		}
	}

	p {
		font-size: 13px;
		color: var(--text-color-secondary);
		line-height: 1.4;
	}
}
