@import "../../assets/styles/media";

.live-travel {
	@include mobile-down {
		min-height: 800px;
		background-image: url("./images/liveTravelBgMobile.jpg");
		background-position: center top;
		position: relative;
		display: flex;
		flex-direction: column;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 131px;
			background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.8) 39.58%, rgba(255, 255, 255, 0) 100%);
		}
	}

	.row {
		height: 268px;
		position: relative;
		z-index: 10;
	}

	.header-wrap {
		max-width: 1083px;
	}

	.row-1 {
		height: 539px;
		display: flex;
		align-items: center;

		@include desktop-down {
			height: 406px;
			align-items: start;
			padding-top: 50px;
		}

		@include tablet-up {
			background: var(--background-color-primary);
		}

		@include mobile-down {
			order: 2;
			padding-top: 8px;
		}
	}

	.row-2 {
		height: 406px;

		@include tablet-down {
			height: auto;
		}

		@include tablet-up {
			background: var(--background-color-secondary);
		}

		@include mobile-down {
			order: 3;
		}

		.content {
			height: 100%;
			display: flex;
			flex-direction: column;

			@include tablet-up {
				padding-bottom: 50px;
			}
		}

		.img-wrap {
			display: flex;
			justify-content: center;

			@include mobile-down {
				flex-direction: column-reverse;
			}
		}
	}

	.col {
		flex: 1;
		text-align: center;
	}

	.col-1 {
		@include tablet-up {
			text-align: right;
		}

		img {
			max-width: 594px;
			margin: -124px 0 0 33px;

			@include desktop-down {
				margin-top: -90px;
			}

			@include mobile-down {
				max-width: 322px;
				margin: 23px 0 0;
			}
		}
	}

	.col-2 {
		img {
			max-width: 500px;
			margin: -158px -67px 0 0;

			@include desktop-down {
				margin: -124px 0 0;
			}

			@include mobile-down {
				max-width: 318px;
				margin: -110px 0 0;
			}
		}
	}

	img {
		width: 100%;
	}
}
