.how-sx-works {
	// height: 520px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;
	position: relative;
	z-index: 0;

	.wrapper-top {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 24px;
	}

	.header-wrap {
		display: flex;
		justify-content: space-between;

		.soundBtnWrap {
			display: inline-block;
			height: max-content;
			cursor: pointer;

			.icon-sound {
				display: inline-block;
				width: 100%;
				height: 100%;
				padding: 8px;
				margin-right: -8px;
			}
		}
	}

	.video-wrap {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		overflow: hidden;
		margin-top: 12px;
		min-height: 520px;

		.video-player {
			display: flex;
			flex: 1 1 auto;
		}

		:global {
			.react-player__preview {
				// min-height: 520px;
				height: auto !important;
			}
		}
	}
}
