@import "../assets/styles/colors";
@import "../assets/styles/media";

.header-not-auth {
	display: flex;
	justify-content: space-between;
	z-index: 20;

	.btn-auth {
		display: flex;
		align-items: center;

		.btn-icon {
			transition: 0.25s;
			width: 4rem;
			height: 4rem;

			@include tablet-up {
				width: 2rem;
				height: 2rem;
				margin-right: 1rem;
			}

			path {
				stroke: $gray;

				@include mobile-down {
					stroke: $cyanLight;
				}
			}
		}

		&:hover .btn-icon {
			transform: scale(1.2);

			path {
				stroke: $violet;
			}
		}

		.btn-text {
			font-size: 1.4rem;
			color: $blueDark;
			font-weight: 700;
			letter-spacing: 0.05rem;
		}
	}
}
