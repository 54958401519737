@import "../../../assets/styles/media";

.accordion-item {
	list-style: none;

	&.active-accordion {
		& .accordion-content {
			height: 400px;

			@include mobile-down {
				height: 228px;
			}

			padding-bottom: 10px;
		}

		& .accordion-title {
			color: var(--blueMediumDark);
		}

		& .plus-minus-icon {
			color: var(--newLink);
		}
	}

	& .accordion-title {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 13px 0;
		border-top: 1px solid rgba(var(--blueDark), 0.1);
		color: var(--newLink);
		font-weight: 700;

		@include mobile-down {
			padding: 6px 0;
		}

		& .title-text {
			margin: 0;
			font-size: 16px;

			& .title-text-extra {
				color: var(--blueDark);
				background-color: var(--cyanLight);
				padding: 4px;
				font-size: 13px;
			}
		}

		&:hover {
			color: var(--blueMediumDark);

			& .plus-minus-icon {
				color: var(--newLink);
			}
		}
	}

	& .accordion-content {
		height: 0;
		overflow: auto;
		transition: 0.3s;

		&.accordion-content-video {
			overflow-y: hidden;
		}

		& .content-text-main {
			font-size: 16px;
			padding-right: 20px;

			li {
				list-style: decimal;
				margin-left: 20px;
				margin-bottom: 12px;
				margin-top: 12px;
			}

			@include mobile-down {
				margin: 0 0 10px;
			}
		}

		& .content-text-list {
			list-style: disc;
			padding-left: 20px;
			padding-right: 20px;
			font-size: 16px;
			margin-top: 12px;

			&.clear-list-style {
				list-style-type: none;
				padding: 0 20px 0 0;
				margin: 0;
			}

			& .content-text-item {
				&.content-text-item-gradient {
					margin-top: 15px;
				}

				& .gradient-wrapper {
					width: 300px;
					max-width: 100%;

					@include mobile-down {
						margin: 5px 0;
					}
				}

				& .risc-description {
					margin: 11px 0 12px 40px;

					@include mobile-down {
						margin: 6px 0 6px 20px;
					}
				}
			}
		}
	}

	& .plus-minus-icon {
		color: var(--dark);
	}
}
