.row {
	font-size: 16px;
	font-weight: 400;
	border-radius: 2px;
	padding: 10px 0;
}

.wrap {
	display: flex;
	align-items: center;
	color: var(--newLink);
	cursor: pointer;

	.label {
		display: inline-block;
		width: 100%;
		color: var(--newLink);
	}

	.icon {
		font-size: 24px;
	}
}
