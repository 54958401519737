.report-history {
	height: 100%;
	padding-top: 20px;

	.show-all-reports-button {
		border: none;
		background: transparent;
		margin: 0;
		padding: 0;
		cursor: pointer;
		font-size: 13px;
		color: var(--newLink);
	}
}
