@import "../../../assets/styles/media";

.travel-to {
	width: 100%;
	max-width: 601px;
	min-height: 330px;
	margin: 0 auto;
	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: center;
	margin-bottom: 150px;
	@include mobile-down {
		margin-bottom: 30px;
	}

	.header {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include mobile-down {
			align-items: flex-start;
			margin-top: 70px;
		}

		.image {
			margin: 80px 0 60px;
			display: block;
			@include mobile-down {
				display: none;
			}
		}

		.image-mobile {
			display: none;
			pointer-events: none;
			@include mobile-down {
				display: block;
				position: absolute;
				top: -137px;
				right: -10px;
				z-index: 0;
			}
		}

		.title {
			font-size: 40px;
			font-weight: 400;
			color: var(--blueDark);
			margin-bottom: 10px;
			@include mobile-down {
				font-size: 26px;
				width: 80%;
			}
		}
	}

	.description {
		width: 100%;
		font-size: 18px;
		font-weight: 400;
		color: var(--gray);
		margin-bottom: 30px;
		text-align: center;

		@include mobile-down {
			font-size: 13px;
			line-height: 14px;
			text-align: left;
		}
	}
}
