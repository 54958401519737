@import "../../../assets/styles/media";

.report-history-item {
	font-size: 13px;
	border: 1px solid var(--grayLight);
	border-radius: 2px;
	margin-bottom: 10px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background: var(--grayLight);
	padding: 4px 8px;
}

.label-pro {
	background: var(--blueMediumDark);
	padding: 0 3px;
	border-radius: 2px;
	font-size: 12px;
	color: var(--cyanLight);
}

.time-wrap {
	display: flex;
	flex-direction: column;
}

.time {
	font-size: 12px;
	color: var(--blueMediumDark);
	line-height: 1.3;
}

.time-title {
	display: inline-block;
	min-width: 70px;
	font-weight: 600;
}

.label-wrap {
	display: flex;
	align-items: center;
	padding: 12px 8px;
	color: var(--newLink);
	.label-icon-arrow {
		font-size: 18px;
	}
}

.label-icon-location {
	font-size: 16px;
	color: var(--gray);
}

.label-text {
	display: inline-block;
	width: 100%;
	color: var(--newLink);
	margin: 0 4px;
}
