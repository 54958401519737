.info-action {
	.row {
		display: flex;
		max-width: 1060px;
		width: 100%;
		margin: 0 auto;
		gap: 20px;

		@media only screen and (max-width: 767px) {
			flex-direction: column;
		}
	}

	.col-2 {
		min-width: 260px;
	}

	.button-buy-report {
		align-items: center;
		background-color: #fff;
		border: 3px solid #6ad400;
		border-radius: 2px;
		color: #6ad400;
		cursor: pointer;
		display: flex;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		justify-content: center;
		line-height: 16.1px;
		max-width: 300px;
		min-height: 44px;
		text-transform: uppercase;
		text-decoration: none;
		transition: 0.3s;
		width: 100%;

		&:hover {
			background-color: #6ad400;
			border: none;
			color: #fff;
		}
	}
}
