@import "../../assets/styles/colors";

.account-delete-wrap {
	text-align: center;

	h2 {
		line-height: 1.25;
	}

	p {
		margin: 1.6rem 0 4.7rem;
	}

	.btn-wrap {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		gap: 20px;
	}

	.btn-delete-account {
		font-size: 1.4rem;
		color: #ff7474;
		text-transform: uppercase;
		letter-spacing: 0.07rem;
		cursor: pointer;
	}
}
