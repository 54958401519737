@import "../../assets/styles/media";

.ai-powered-risk-intelligence-brief {
	background: url("./images/aiPoweredRiskIntelligenceBrief.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	padding: 150px 0 100px;

	@include mobile-down {
		padding: 50px 0 70px;
	}
}
