@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

.risk-highlights-indicator-wrapper {
	background: var(--background-color-secondary);
	max-width: 320px;
	width: 100%;
	box-sizing: border-box;
	padding: 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	position: relative;
	text-align: center;
	transition: 0.3s;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);

	@include mobile-down {
		max-width: 495px;
	}

	@include phablet-down {
		max-width: none;
	}

	&:hover {
		background: rgba(196, 198, 200, 0.9);
	}

	& p {
		max-width: 100%;
		margin: 0;
		font-size: 11.43px;
		line-height: 1.25;
		color: var(--text-color-primary);

		&:last-child {
			margin-top: auto;
			font-size: 10px;
			font-weight: bold;
		}
	}
}

.rating-text-info {
	font-size: 16px;
	line-height: 140%;
	text-align: left;
	margin-bottom: 15px;

	.rating-name {
		color: var(--text-color-primary);
		font-size: 20px;
		font-weight: 400;
	}
}

.risk-highlights-indicator {
	width: 100%;
}

.detail-preview {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: relative;
	width: 100%;
	height: 160px;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;

	@include mobile-down {
		height: 205px;
	}

	@include phablet-down {
		height: 160px;
	}
}

.detail-preview-img {
	position: absolute;
	width: 100%;
	min-width: 300px;
	height: auto;
	min-height: 160px;
	top: 0;
}

.detail-preview-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 8px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 0;
	background-color: var(--button-background-color-secondary);
	position: relative;
	z-index: 2;
	transition: 0.2s;
	cursor: pointer;
	color: var(--button-text-color-secondar);
	font-size: 14px;
	font-weight: 700;

	&:hover {
		width: 100%;
		border-radius: 2px;
		transition: 0.3s;

		& .btn-extra-text {
			transform: scale(1) translate(-50%, -50%);
			opacity: 1;
		}
	}

	@include mobile-down {
		width: 100%;
		border-radius: 2px;
		transform: scale(1);
	}
}

.btn-extra-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: scale(0) translate(-50%, -50%);
	width: 100%;
	transition: opacity 0.2s 0.15s;
	opacity: 0;

	@include mobile-down {
		width: 100%;
		border-radius: 2px;
		top: 50%;
		left: 50%;
		transform: scale(1) translate(-50%, -50%);
		opacity: 1;
	}
}

.plus-icon {
	font-size: 24px;
	font-weight: 400;
	position: absolute;
	right: 8px;
	font-variation-settings: "FILL" 0, "wght" 500, "GRAD" -25, "opsz" 48;
}

.detail-preview:after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.2);
}
