@import "../assets/styles/colors";

.world {
	svg path {
		// fill: $gray;

		&:hover {
			stroke-width: 3;
			stroke: #18a0fb;
		}
	}

	.tooltip-country {
		position: fixed;
		display: flex;
		background: rgba(0, 0, 0, 0.7);
		box-shadow: 0 0.5rem 1rem rgba(0, 24, 32, 0.07);
		backdrop-filter: blur(0.2rem);
		border-radius: 2rem 2rem 2rem 0;
		padding: 0.6rem 0.8rem 0.6rem 1.2rem;
		opacity: 0;
		z-index: 10;

		&-active {
			opacity: 1;
			transition: opacity 0.3s;
		}

		.tooltip-country-name {
			font-size: 1.6rem;
			color: $white;
			font-weight: 700;
			line-height: 1.4;
		}

		.tooltip-country-index {
			font-size: 1.6rem;
			color: $white;
			line-height: 1.4;
			margin-left: 0.4rem;

			.military-icon {
				color: #ff0000;
				font-variation-settings: "wght" 400;
			}
		}
	}
}
