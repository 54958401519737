.block {
	height: 100%;
	padding: 24px 24px 30px 24px;
	display: flex;
	flex-direction: column;
}

.block-img {
	padding-bottom: 16px;
}

.image {
	width: 100%;
	height: 186px;
}

.block-text-button {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.block-text {
	display: flex;
	flex-direction: column;
}

.text-description {
	padding-bottom: 16px;
}

.text-detail {
	padding-bottom: 20px;
	font-size: 13px;
	line-height: 18px;
}
