.similar-cities-info {
	margin-left: 21px;
	padding: 8px 0;

	.similar-cities-info-header {
		display: flex;

		.graph-color-icon {
			min-width: 16px;
		}

		h3 {
			font-size: 16px;
			margin-bottom: 5px;
		}

		p {
			max-width: 180px;
			font-size: 13px;
			color: var(--gray);
			line-height: 1.5;
		}
	}
}
