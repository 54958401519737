.container {
	width: 100%;

	.header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	/* --- search --- */
	.searchContainer {
		position: relative;
		display: flex;
		align-items: center;
	}

	.searchIcon {
		position: absolute;
		left: 10px;
		color: #aaa;
		pointer-events: none;
	}

	.searchInput {
		padding: 8px 12px 8px 35px;
		border: none;
		border-radius: 6px;
		font-size: 14px;
		min-width: 250px;

		width: 450px;
		height: 46px;

		background: var(--white);
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		border-radius: 0px;

			&::placeholder {
				color: var(--gray) !important;
				opacity: 1 !important;
			}

			&::-webkit-input-placeholder {
				color: var(--gray) !important;
				opacity: 1 !important;
			}

			&::-moz-placeholder {
				color: var(--gray) !important;
				opacity: 1 !important;
			}
	}

	.searchInput:focus {
		outline: none;
		box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
	}

	.wrapper {
		background: var(--white);
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		border-radius: 10px;

		padding: 20px;
		gap: 10px;
		margin: 0 20px;
	}

	.tableWrapper {
		overflow-x: auto;
	}

	/* --- table --- */
	.userTable {
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 20px;

		.statusActive {
			color: var(--green);
			text-transform: uppercase;
		}

		.statusDisable {
			color: var(--vicriWar);
			text-transform: uppercase;
		}
	}

	.userTable th.email,
	.userTable td.email {
		max-width: 150px;

		a {
			color: var(	--newLink);
		}
	}

	.userTable td.role {
		text-transform: lowercase;
	}

	.userTable td.reports {
		text-align: end;
		padding: 0 10px;
	}

	.userTable th,
	.userTable td {
		word-wrap: break-word;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;

		height: 48px;
		text-align: left;
		border-bottom: 1px solid var(--grayLight);
		vertical-align: middle;
	}

	.userTable th {
		padding: 8px 12px;
		gap: 8px;
		background: var(--blueMediumDark);
		border-width: 0px 1px;
		border-style: solid;
		border-color: var(--textGray);
		color: var(--white);
		padding: 0 5px;
	}

	.userTable th.center, .userTable td.center {
		text-align: center;
	}

	.userTable tbody tr:last-child td {
		border-bottom: none;
	}

	.userTable tbody tr:hover {
		background-color: var(--grayLight);
	}

	/* --- action button --- */
	.actionButton {
		background: none;
		border: none;
		padding: 5px;
		cursor: pointer;
		color: var(--gray);
		border-radius: 4px;
	}
	.actionButton:hover {
		 background-color: var(--grayLight);
	}

	.actionIcon {
		display: block;
	}

	/* --- pagination --- */
	.pagination {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		padding-top: 15px;
	}

	.pageButton,
	.pageNumber,
	.ellipsis {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 32px;
		height: 32px;
		padding: 0 8px;
		border: 1px solid rgba(0, 0, 0, 0.0);
		background-color: rgba(0, 0, 0, 0.0);
		color: var(--blueDark);
		font-size: 14px;
		border-radius: 4px;
		cursor: pointer;
		transition: background-color 0.2s, color 0.2s;
		user-select: none;
	}

	.pageButton:hover,
	.pageNumber:hover {
		background-color: var(--grayLight);
	}

	.pageButton:disabled {
		color: #adb5bd;
		cursor: not-allowed;
		background-color: rgba(0, 0, 0, 0.0);
	}

	.pageButton:disabled:hover {
			background-color: rgba(0, 0, 0, 0.0);
	}


	.currentPage {
		background-color: var(--newLink);
		color: white;
		border-color: var(--newLink);
		cursor: default;
	}

	.currentPage:hover {
		background-color: var(--border-color-primary-hover);
		border-color: var(--border-color-primary-hover);
	}

	.ellipsis {
		border: none;
		background: none;
		color: var(--textGray);
		cursor: default;
		padding: 0 4px;
	}

	.noResults {
			text-align: center;
			padding: 40px 20px;
			color: var(--textGray);
			font-style: italic;
	}
}
