@import "../assets/styles/colors";

.similar-cities-conflict-or-nodata {
	background: $grayLight;
	width: 29.3rem;
	height: 9.6rem;
	box-shadow: 0 0.5rem 1rem rgba(0, 24, 32, 0.07);
	border-radius: 1rem;
	padding: 0.8rem 2rem;
	color: var(--text-color-primary);

	&.similar-cities-conflict {
		background: repeating-linear-gradient(45deg, #fff, #fff 10px, #f8f9f9 10px, #f8f9f9 20px);
	}

	.content-wrap {
		display: flex;
		justify-content: space-between;
		height: 100%;
	}

	.left-block {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		width: 60%;
		margin-top: 0.5rem;

		.text-wrap {
			width: 100%;
		}

		.location {
			font-size: 1.6rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.location-title {
			font-size: 1.2rem;
		}
	}

	.right-block {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		text-align: right;

		.icon {
			max-width: 12.5rem;
		}

		.text-block {
			font-size: 1.2rem;
			font-weight: 700;
			line-height: 1.1;
			margin-top: 1.1rem;
		}
	}

	@media only screen and (max-width: 767px) {
		width: 100%;
	}
}
