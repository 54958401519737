@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

$spacingUnit: 8px; //##
// $grayColor500: #9e9e9e; //##
$colorShadow: #001a21;

.dialog-wrapper {
	color: $blueDark;

	& .dialog-paper {
		box-sizing: content-box;
		// width: 680px;
		// height: 700px;
		min-height: 680px;
		max-height: 729px;
		height: 90%;
		// max-width: 680px; //##
		// padding: 10px 20px 0;
		padding: 20px;
		border-radius: 10px;

		@include mobile-down {
			margin: 18px;
			min-height: auto;
			max-height: 660px;
		}
	}

	& .dialog-title {
		// font-size: 20px;
		margin: 0 0 5px;
		// padding: $spacingUnit * 2;
		// padding: 0 20px 0;
		padding: 0;
		// color: $blueDark;
	}

	& .dialog-title-text {
		font-size: 20px;
		color: $blueDark;
		padding: 0;
	}

	& .close-button {
		position: absolute;
		// right: $spacingUnit;
		right: 9px;
		// top: $spacingUnit;
		top: 7px;
		color: $colorShadow;
	}

	& .report-help-popap {
		margin: 0;
		overflow-y: hidden;
		// padding: 0 20px;
		padding: 0;
	}

	@include mobile-down {
		// & .dialog-paper {
		//     margin: 18px;
		// }

		& .paper {
			min-width: 100%;
		}
	}
}
