.modal-overlay {
	background: rgba(35, 53, 68, 0.9);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px 20px;

	.modal-content {
		width: 100%;
		max-width: 676px;
		background: #fff;
		box-shadow: 0px 12px 30px rgba(0, 26, 33, 0.3);
		border-radius: 12px;
		position: relative;
		overflow: hidden;
	}

	.modal-btn-close {
		position: absolute;
		top: 15px;
		right: 15px;
		border: none;
		background: transparent;
		cursor: pointer;
		z-index: 20;

		&:hover {
			.modal-icon-close {
				transform: scale(1.2);
			}
		}
	}

	.modal-icon-close {
		font-size: 30px;
		transition: 0.3s;
	}
}
