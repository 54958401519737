.risk-score-dinamics-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon-dinamics-wrap {
		width: 48px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 32px;
		font-size: 30px;
		color: var(--white);
	}

	&-increase {
		.icon-dinamics-wrap {
			background: #ff6401;
		}
	}

	&-decrease {
		.icon-dinamics-wrap {
			background: var(--green);
		}
	}

	&-no-significant-change {
		.icon-dinamics-wrap {
			transform: rotate(90deg);
			background: var(--gray);
		}
	}

	.label {
		font-size: 11px;
		color: var(--gray);
		line-height: 1.4;
		margin-top: 6px;
	}

	.value {
		font-size: 24px;
		color: var(--blueDark);
		font-weight: 700;
		line-height: 1.44;
	}
}
