.button {
	height: 44px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 4px;
	background: transparent;
	border: 2px solid transparent;
	border-radius: 2px;
	padding: 5px 12px;
	cursor: pointer;
	transition: 0.25s;

	&:hover:not(.disabled) {
		box-shadow: 0px 4px 8px 0px rgba(0, 24, 32, 0.12);
	}

	&:active:not(.disabled) {
		box-shadow: 0px 1px 2px 0px rgba(0, 24, 32, 0.24);
	}

	&.disabled {
		cursor: auto;
	}

	.btn-icon {
		font-size: 18px;
	}

	&.size-large {
		height: 52px;
		padding-left: 24px;
		padding-right: 24px;

		.btn-text {
			font-size: 18px;
		}
	}
}

.variant-filled {
	&.color-primary {
		background: var(--button-background-color-primary);
		color: var(--button-text-color-primary);

		&:visited {
			color: var(--button-text-color-primary);
		}

		&:hover:not(:active):not(.disabled) {
			background: var(--button-background-color-primary-hover);
			color: var(--button-text-color-primary-hover);
		}

		&.disabled {
			background: var(--button-background-color-primary-disabled);
			color: var(--button-text-color-primary-disabled);
		}
	}

	&.color-secondary {
		background: var(--button-background-color-secondary);
		color: var(--button-text-color-secondary);

		&:visited {
			color: var(--button-text-color-secondary);
		}

		&:hover:not(:active):not(.disabled) {
			background: var(--button-background-color-secondary-hover);
			color: var(--button-text-color-secondary-hover);
		}

		&.disabled {
			background: var(--button-background-color-secondary-disabled);
			color: var(--button-text-color-secondary-disabled);
		}
	}
}

.variant-outlined {
	color: var(--button-text-color-secondary);
	border-color: var(--border-color-primary);

	&:visited {
		color: var(--button-text-color-secondary);
	}

	&:hover:not(:active):not(.disabled) {
		color: var(--button-text-color-secondary-hover);
		border-color: var(--border-color-secondary-hover);
	}

	&.disabled {
		color: var(--button-text-color-secondary-disabled);
		border-color: var(--border-color-primary-disabled);
	}
}

.variant-text {
	color: var(--button-text-color-secondary);

	&:visited {
		color: var(--button-text-color-secondary);
	}

	&:hover:not(:active):not(.disabled) {
		color: var(--button-text-color-secondary-hover);
	}

	&.disabled {
		color: var(--button-text-color-secondary-disabled);
	}
}

.btn-text {
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
}

.size-width-full {
	width: 100%;
}
