.link-box {
	display: flex;
	align-items: center;
	background: transparent;
	border-radius: 2px;
	font-size: 13px;
	transition: 0.25s;

	.link {
		display: inline-flex;
		align-items: center;
		gap: 4px;
		color: var(--text-link-color-primary);
		text-decoration: none;
		padding: 8px 0;
		cursor: pointer;
		transition: 0.25s;

		&:visited {
			color: var(--text-link-color-primary);
		}

		&:hover {
			color: var(--text-link-color-primary-hover);
		}

		&:active {
			color: var(--text-link-color-primary);
		}
	}

	.btn-icon {
		font-size: 18px;
	}
}

.variant-text {
	box-shadow: none;
}

.variant-filled {
	.link {
		padding: 8px;
		flex: auto;
	}

	&.color-primary {
		background: var(--link-background-primary);

		&:hover {
			background: var(--link-background-primary-hover);
			box-shadow: 0px 4px 8px 0px rgba(0, 24, 32, 0.08);
		}

		&:active {
			background: var(--link-background-primary);
			box-shadow: 0px 1px 2px 0px rgba(0, 24, 32, 0.18);
		}
	}

	&.color-secondary {
		color: var(--button-text-color-secondary);
	}
}

.size-width-full {
	width: 100%;
}
