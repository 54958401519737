@import "../../assets/styles/media";

.step-card {
	max-width: 385px;
	width: 100%;
	height: 174px;
	background: var(--background-color-secondary);
	border-radius: 20px;
	padding: 30px 20px 40px 82px;

	@include desktop-down {
		padding-left: 62px;
	}
}

.header {
	display: flex;
	align-items: center;
	gap: 16px;

	@include desktop-down {
		gap: 8px;
	}

	.title {
		@include desktop-down {
			font-size: 22px !important;
		}
	}
}

.num {
	width: 46px;
	height: 46px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	background: var(--health);
	padding: 6px 16px;
	font-size: 24px;
	color: var(--text-color-tertiary);
	margin-left: -62px;
	align-self: start;

	@include desktop-down {
		width: 34px;
		height: 34px;
		font-size: 22px;
		border-radius: 8px;
		margin-left: -42px;
	}
}

.description {
	color: var(--text-color-secondary);
	font-weight: 700;
	line-height: 1.4;
	margin-top: 14px;

	@include mobile-down {
		font-size: 14px;
		line-height: 1.5;
		margin-top: 17px;
	}
}
