@import "../../assets/styles/media";

.layout-dashboard {
	height: 100%;

	.content {
		padding-top: 100px;
	}

	@include tablet-down {
		.content {
			padding: 30px 20px 0;
		}
	}

	.page-footer {
		margin-top: auto;
	}

	:global {
		@include narrower-than(1399px) {
			.stacked-cards {
				margin-top: 72px;
			}
			.page-footer-container.footer-mob .footer-content {
				padding: 22px 0;
			}
		}

		@include tablet-down {
			.stacked-cards {
				margin: 72px 0 0 !important;

				&.stacked-cards-empty {
					margin-top: 35px !important;
				}
			}
		}
	}
}
