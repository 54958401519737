@import "../../assets/styles/media";

.improve-safe-esteem {
	min-height: 521px;
	background: url("./images/ImproveSafeEsteemBg.png"), linear-gradient(305deg, #334b5d 39.2%, #6ca3ad 120.97%), #334b5d;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;

	@include tablet-down {
		min-height: auto;
		padding-bottom: 50px;
	}

	@include mobile-down {
		background-size: 2880px auto;
		background-position: left 40% top 21%;
	}

	.header {
		max-width: 710px;
		color: var(--text-color-tertiary);

		:global {
			.h2 {
				line-height: 1.4;

				@include mobile-down {
					font-size: 22px;
					line-height: 1.1;
				}
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 120px;

		@include tablet-down {
			padding-top: 50px;
		}
	}

	.btn-wrap {
		width: 222px;
		display: flex;
		justify-content: center;
		margin-top: 90px;

		@include mobile-down {
			margin-top: 41px;
		}
	}
}
