@import "../../assets/styles/media";

.info-cards {
	min-height: 227px;
	background: var(--background-color-tertiary);
	display: flex;
	justify-content: center;

	.content {
		max-width: 1440px;
		height: 227px;
		display: flex;
		position: relative;
		padding-left: 0;
		padding-right: 0;

		@include mobile-down {
			flex-direction: column;
			height: auto;
		}

		&::after {
			content: "";
			background: url("./images/bg01.png") no-repeat;
			background-position: center bottom;
			background-size: cover;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}

	.col {
		width: calc(100% / 3);
		display: flex;
		justify-content: center;
		position: relative;
		padding: 23px 118px;
		color: var(--white);

		@include desktop-down {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include mobile-down {
			width: 100%;
			height: 181px;
			padding: 20px;
		}
	}

	.col-1,
	.col-3 {
		background: #268887;
	}

	.text-wrap {
		max-width: 250px;
		position: relative;
		z-index: 10;

		@include mobile-down {
			max-width: 315px;

			:global {
				br {
					display: none;
				}
			}
		}
	}

	.description {
		font-size: 16px;
		margin-top: 30px;

		@include desktop-down {
			margin-top: 20px;
		}
	}
}
