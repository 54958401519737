@import "../../../assets/styles/media";

.key-personal-risk-drivers {
	height: auto;
	padding: 20px 20px 40px;

	@include mobile-down {
		padding: 20px;
	}

	.key-personal-risk-drivers-container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.header {
		display: flex;
		justify-content: space-between;
	}

	.title {
		margin-right: 5px;
	}

	.block-text {
		display: flex;
		flex-wrap: wrap;
	}

	:global {
		.block-info-icon {
			margin-top: 0;
			color: var(--text-color-secondary);
			cursor: pointer;

			&:hover {
				color: var(--violet);
			}
		}

		.icon-info {
			font-size: 24px;
		}
	}

	.label {
		font-size: 13px;
		color: var(--text-color-secondary);
		flex-basis: 100%;
	}

	.block-body {
		height: 100%;
		display: flex;
		align-items: center;
		gap: 48px;
		text-align: center;
		margin-top: 20px;

		@include mobile-down {
			flex-direction: column;
			gap: 18px;
		}
	}

	.block-left {
		position: relative;
		max-width: 208px;

		.location {
			position: absolute;
			width: 100%;
			left: 50%;
			bottom: -20px;
			-webkit-transform: translateX(-50%);
			-moz-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			-o-transform: translateX(-50%);
			transform: translateX(-50%);
			color: var(--text-color-primary);
			font-weight: 700;
		}
	}

	.relative-risk-chart {
		position: relative;
		line-height: 1;
	}

	:global {
		.pie-chart {
			width: 140px;
			height: auto;
		}
	}

	.risk-location-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 30px;
	}

	.block-right {
		width: 100%;
	}

	.graph-color {
		display: flex;
		flex-direction: column;
		font-size: 13px;
		color: var(--dark);
		margin-top: 0;
	}

	.graph-color-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		padding: 12px 0 5px;
		border: none;

		&:not(:last-of-type) {
			border-bottom: 1px solid rgba(35, 53, 68, 0.1);
		}
	}

	.wrap {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}

	:global {
		.graph-color-icon {
			min-width: 32px;
			margin-top: 3px;
		}
	}

	.data-title {
		display: flex;
		justify-content: space-between;

		.risk-name {
			font-size: 16px;
			margin-left: 6px;
			color: var(--text-color-primary);
		}

		.value {
			font-size: 24px;
			font-weight: 700;
			color: var(--text-color-primary);
		}
	}

	.graph-color-data {
		color: var(--text-color-primary);
		width: 100%;
		text-align: left;

		span {
			font-size: 16px;
		}
	}
}
