html,
body,
header,
section,
nav,
article,
aside,
footer,
div,
span,
hr,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: 0;
	border: none;
	outline: 0;
	vertical-align: top;
	background: transparent;
	box-sizing: border-box;
}
a {
	text-decoration: none;
}
fieldset,
img {
	border: 0;
	border: none;
}
img {
	display: inline-block;
}
ol,
ul {
	list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}
:focus,
:active {
	outline: 0;
	ie-dummy: expression(this.hideFocus=true); /* IE6-7 */
}
