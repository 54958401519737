.report-navigation-mobile-wrap {
	.nav-content {
		max-width: 1060px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-around;
	}

	.nav-item {
		flex: 1;
		background: transparent;
		border: none;
		display: flex;
		justify-content: center;
		padding: 10px;
		box-sizing: border-box;
		position: relative;

		&:not(:first-of-type) {
			&:before {
				content: "";
				position: absolute;
				top: 30%;
				left: 0;
				height: 40%;
				width: 1px;
				background: #dee2e5;
			}
		}
	}

	.btn-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3px;
		position: relative;
		color: var(--text-color-primary);

		&.nav-active {
			color: var(--violet);

			.btn-text {
				font-weight: 700;
			}
		}
	}

	.btn-text {
		font-size: 12px;
	}

	.sup-icon {
		position: absolute;
		top: -5px;
		left: calc(50% + 10px);
		font-size: 12px;
	}
}
