.travel-risk-about {
	height: 100%;
	padding: 24px;
	background: #f8f9f9;
	border-radius: 0 0 10px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.info-title {
		color: var(--blueDark);
		font-size: 16px;
		font-weight: 700;
		padding-bottom: 15px;
	}

	.info-text {
		font-size: 13px;
		color: var(--gray);
		line-height: 1.4;
	}
}
