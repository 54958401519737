@import "../../../assets/styles/media";

.menu-items {
	display: flex;
	gap: 20px;
	margin-right: 20px;

	.nav-item {
		display: flex;
		align-items: center;
		background: transparent;
		color: var(--text-color-secondary);
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		border: none;
		cursor: pointer;
		padding: 7px 0;
		position: relative;

		&:after {
			content: "";
			background: var(--violet);
			border-radius: 2px;
			width: 100%;
			height: 3px;
			position: absolute;
			left: 0;
			bottom: -2px;
			transform: scaleX(0);
			transition: 0.25s;
		}

		&:not(.nav-item-active):hover::after {
			transform: scaleX(0.5);
		}

		&.nav-item-active {
			color: var(--text-color-primary);
			&::after {
				transform: scaleX(1);
			}
		}
	}

	.btn-content {
		position: relative;
		white-space: nowrap;
	}

	.btn-icon {
		top: -4px;
		right: -9px;
		color: var(--violet);
		position: absolute;
		font-size: 11px;
	}
}
