@import "../../../assets/styles/media";

.risk-highlights-indicator-wrapper-more {
	background: var(--while);
	max-width: 320px;
	width: 100%;
	height: 100%;
	min-height: 300px;
	box-sizing: border-box;
	padding: 20px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	transition: 0.3s;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	color: #5b6a76;

	@include mobile-down {
		max-width: 495px;
	}

	&:hover {
		background: rgba(196, 198, 200, 0.9);
	}
}

.title {
	align-self: flex-start;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.15;
	text-align: left;
	padding: 10px 0 0 0;
}

.main-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.more-avaiables-list {
	width: 100%;
	font-size: 13px;
	font-weight: 400;
	line-height: 18.2px;
	list-style-type: none;
}

.more-avaiable-item {
	padding: 1px 0;
	display: flex;
	align-items: baseline;
	text-align: left;

	.lock-icon {
		font-size: 10px;
		padding-right: 5px;
	}
}

.button-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}
