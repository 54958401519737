.risk-highlights-indicator-gradient {
	position: relative;
	width: 100%;
	height: 34px;
	border-radius: 2px;
}

.gradient-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 30%, #ffffff 120%);
}

.gradient-content {
	display: flex;
	align-items: center;
	position: relative;
	height: 100%;
	z-index: 1;
	color: var(--dark);
	padding: 0 7px;
	opacity: 0.8;
}

.rating-description {
	display: flex;
	align-items: center;
	font-size: 16px;
}

.rating-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.rating-smile {
	display: inline-block;
	// padding: 5px;
	margin-right: 7px;
}

.rating-value {
	font-size: 24px;
	font-weight: 700;
}

.gradient-bg-risk-1 {
	background-color: var(--colorRisk1);
}

.gradient-bg-risk-2 {
	background-color: var(--colorRisk2);
}

.gradient-bg-risk-3 {
	background-color: var(--colorRisk3);
}

.gradient-bg-risk-4 {
	background-color: var(--colorRisk4);
}

.gradient-bg-risk-5 {
	background-color: var(--colorRisk5);
}
