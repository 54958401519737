@import "../../assets/styles/media";

.page-get-started {
	background: var(--blueMediumDark);
	height: 100%;

	.content {
		position: relative;

		@include mobile-down {
			height: 100%;
			padding: 0;
		}
	}

	.header-top {
		margin-bottom: 90px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include tablet-down {
			height: 55px;
			align-items: center;
			margin-bottom: 0;

			.logo {
				img {
					height: 30px;
				}
			}
		}

		@include mobile-down {
			padding: 0 20px;
		}
	}

	.main {
		display: flex;
		justify-content: space-between;

		@include mobile-down {
			flex-direction: column;
			box-sizing: border-box;
			flex: auto;
		}
	}

	.box-left {
		max-width: 40%;
		width: 100%;
		color: var(--white);

		@include tablet-down {
			max-width: 40%;
			width: 100%;
		}

		@include mobile-down {
			max-width: none;
			padding: 35px 20px 5px;
		}

		h1 {
			@include tablet-down {
				font-size: 27px;
			}
		}

		p {
			color: var(--grayLight);
			margin-top: 23px;

			@include mobile-down {
				font-size: 13px;
			}
		}

		.app-download-container {
			display: flex;
			margin-top: 20.8px;
		}

		.btn-store {
			margin-right: 11.2px;

			a {
				display: inline-block;
				height: 41px;
			}

			img {
				height: 100%;
			}

			&:hover {
				opacity: 0.7;
			}
		}

		.link {
			display: inline-block;
			margin-top: 40px;
			display: flex;
			align-items: center;
			font-size: 14px;
			color: var(--cyanLight);
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.7px;

			@include mobile-down {
				margin-top: 15px;
			}

			.arrow-icon {
				font-size: 18px;
			}
		}

		.block-img {
			max-width: 322px;
			margin-top: 30px;

			@include tablet-down {
				max-width: 260px;
			}

			img {
				width: 100%;
			}
		}
	}

	.box-right {
		margin: 0 117px 0 16px;
		height: auto;

		@include tablet-up {
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translateY(-50%);
		}

		@include desktop-down {
			margin-right: 0;
		}

		@include tablet-down {
			max-width: 50%;
			width: 100%;
		}

		@include mobile-down {
			height: 100%;
			max-width: none;
			width: auto;
			margin: 0;
			display: flex;
			flex-direction: column;
			flex: auto;
		}

		h3 {
			@include mobile-down {
				font-size: 18px;
			}
		}

		:global {
			.intro-container {
				@include mobile-down {
					height: 100%;
					border-radius: 0;
					padding: 20px;
					justify-content: space-around;
					flex: auto;
				}
			}
		}

		.block-btn {
			@include mobile-down {
				margin-top: 0;
			}
		}
	}

	.block-img.desktop {
		@include mobile-down {
			display: none;
		}
	}
}
