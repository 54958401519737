@import "../../assets/styles/media";

.content {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding-left: 118px;
	padding-right: 118px;

	@include desktop-down {
		padding-left: 40px;
		padding-right: 40px;
	}

	@include mobile-down {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.welcome-page {
	color: var(--text-color-primary);
	line-height: 1.2;
	text-align: left;

	// .loader {
	// 	opacity: 0;
	// 	transition: 1s 2s;
	// }

	// .page-content {
	// 	opacity: 1;
	// 	transition: 1s 2s;
	// }

	// &.loading {
	// 	.loader {
	// 		opacity: 1;
	// 		transition: 1s 2s;
	// 	}

	// 	.page-content {
	// 		opacity: 0;
	// 		transition: 1s 2s;
	// 	}
	// }

	@include mobile-down {
		line-height: 1.5;
	}

	:global {
		h1 {
			font-size: 60px;
			line-height: 1.3;

			@include mobile-down {
				font-size: 32px;
				letter-spacing: -0.25px;
				line-height: 1.1;
			}
		}

		h2 {
			font-size: 40px;
			line-height: 1.2;

			@include tablet-down {
				font-size: 32px;
			}

			@include mobile-down {
				line-height: 1.1;
			}
		}

		h3 {
			font-size: 28px;
			line-height: 1.4;

			@include tablet-down {
				font-size: 22px;
			}

			@include mobile-down {
				line-height: 1.1;
			}
		}

		.text-large {
			font-size: 24px;
			line-height: 1.4;

			@include mobile-down {
				line-height: 1.5;
			}
		}

		.text-middle {
			font-size: 16px;

			@include mobile-down {
				line-height: 1.5;
			}
		}

		.text-small {
			font-size: 14px;

			@include mobile-down {
				line-height: 1.5;
			}
		}

		.text-bold {
			font-weight: 700;

			@include mobile-down {
				line-height: 1.5;
			}
		}

		.welcome-shadow {
			box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
		}

		.welcom-border-radius {
			border-radius: 18px;
		}
	}

	.text-center {
		text-align: center;
	}

	img {
		width: 100%;
	}
}
