.form-field-wrap {
	border-bottom: 1px solid var(--grayLight);
	padding: 8px 0;
	position: relative;

	&.form-field-wrap-user-name {
		.field-value {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.field-inner {
		padding: 3px 0;
		position: relative;
	}

	.MuiTextField-root {
		height: 22px;
	}

	.input-wrap {
		::placeholder {
			color: var(--grayLight) !important;
			opacity: 1 !important;
		}

		::-webkit-input-placeholder {
			color: var(--grayLight) !important;
			opacity: 1 !important;
		}

		::-moz-placeholder {
			color: var(--grayLight) !important;
			opacity: 1 !important;
		}

		input:-moz-placeholder {
			color: var(--grayLight) !important;
			opacity: 1 !important;
		}

		input {
			color: var(--text-color-primary) !important;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-text-fill-color: var(--text-color-primary) !important;
		}

		input:-internal-autofill-selected {
			background-color: var(--background-color-secondary) !important;
		}
	}

	.btn {
		display: flex;
		align-items: center;
		position: absolute;
		top: 50%;
		right: 12px;
		cursor: pointer;
		transform: translateY(-50%);

		&.btn-edit {
			opacity: 0;
			transition: opacity 0.25s;
		}
	}

	.field-inner-focus {
		.help-text {
			color: var(--text-color-secondary);
		}
	}

	.help-text {
		display: inline-block;
		font-size: 13px;
		color: var(--text-color-secondary);
		line-height: 1.2;

		&.field-error {
			color: red;
		}

		&.help-text-password {
			position: absolute;
			bottom: -50%;
		}
	}

	&:hover .btn-edit {
		opacity: 1;
		transition: opacity 0.25s;
	}
}
