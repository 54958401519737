@import "../../../assets/styles/media";

.searched-wrap {
	max-height: 470px;
}

.header {
	display: flex;
	justify-content: space-between;
}

.all-history-block {
	display: flex;
	justify-content: space-between;
	font-size: 13px;
}

.link-all-history {
	display: flex;
	align-items: center;
	gap: 10px;
	color: var(--newLink);

	&:visited {
		color: var(--newLink);
	}

	.label-icon-arrow {
		font-size: 18px;
	}
}

.description-all-history {
	color: var(--textGray);
}

.free {
	overflow: hidden;
	height: 255px;
	padding-right: 0;
}

.purchased {
	padding-right: 0;

	.body {
		max-height: 180px;
	}
}

.all {
	.body {
		@include tablet-up {
			max-height: 440px;
		}
	}
}

.title {
	color: var(--blueDark);
	font-size: 26px;
	font-weight: 400;
	margin-bottom: 12px;
}

.body {
	margin-top: 12px;
	@include tablet-down {
		margin-top: 2px;
		padding-bottom: 5px;
	}
}

.list {
	:global {
		.report-history-item {
			margin-bottom: 8px;
		}
	}
}

.stub {
	font-size: 13px;
	color: #5b6a76;
}
