@import "./assets/styles/reset";
@import "./assets/styles/fonts";
@import "./assets/styles/colors";
@import "./assets/styles/colorConstants";
@import "./assets/styles/media";
// @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

html {
	height: 100%;
	background: #f8f9f9;
	font-size: 10px;
	font-family: "Arimo", "Roboto", "Helvetica", "Arial", sans-serif;
}

body,
#root {
	margin: 0;
	padding: 0;
	background: #f8f9f9;
}

body {
	height: 100%;
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	line-height: 140%;

	&.bg-blue-medium-dark {
		background: $blueMediumDark;
	}
}

.material-symbols-outlined {
	font-variation-settings:
		"FILL" 0,
		"wght" 300,
		"GRAD" -25,
		"opsz" 48;

	&.fw-100 {
		font-variation-settings: "wght" 100;
	}
	&.fw-200 {
		font-variation-settings: "wght" 200;
	}
	&.fw-300 {
		font-variation-settings: "wght" 300;
	}
	&.fw-400 {
		font-variation-settings: "wght" 400;
	}
	&.fw-500 {
		font-variation-settings: "wght" 500;
	}
	&.fw-600 {
		font-variation-settings: "wght" 600;
	}
	&.fw-700 {
		font-variation-settings: "wght" 700;
	}
}

.content {
	display: flex;
	flex-direction: column;
	max-width: 1440px;
	min-height: 100vh;
	height: 100%;
	margin: 0 auto;
	padding: 30px;
	box-sizing: border-box;
}

.hide {
	display: none;
}

.hide-mobile {
	@include mobile-down {
		display: none;
	}
}

.show-mobile {
	@include mobile-down {
		display: block;
	}
}

.hide-desktop {
	@include tablet-up {
		display: none;
	}
}

.show-desktop {
	@include tablet-up {
		display: block;
	}
}

h1,
h3 {
	line-height: 115%;
}

h1 {
	font-size: 40px;

	span {
		display: block;
		font-size: inherit;
		font-weight: 700;
		line-height: inherit;
	}

	@include mobile-down {
		font-size: 32px;
	}
}

h2 {
	// TODO: need to clarify size
	// font-size: 26px;
	font-size: 32px;
	line-height: 130%;

	@include mobile-down {
		font-size: 22px;
	}
}

h3 {
	// TODO: need to clarify size
	// font-size: 20px;
	font-size: 26px;
	@include mobile-down {
		font-size: 20px;
	}
}

.section-wrap-white {
	background: var(--background-color-secondary);
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
}

.error-message {
	font-size: 11px;
	line-height: 140%;
}

.arrow-up {
	color: $crime;
}

.arrow-down {
	color: $green;
}

.text-center {
	text-align: center;
}

.flex-auto {
	flex: auto;
}

.divider {
	border-top: 1px solid $grayLight;
}

.overflow-row-1 {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.overflow-row-3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	// max-height: 60px;
}

.overflow-row-5 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	// max-height: 60px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px #334b5d inset !important;
	-webkit-box-shadow: 0 0 0 30px #334b5d inset !important;
	-webkit-text-fill-color: white !important;
}
form.light {
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		box-shadow: 0 0 0 30px #fff inset !important;
		-webkit-box-shadow: 0 0 0 30px #fff inset !important;
		-webkit-text-fill-color: #233544 !important;
	}

	.success {
		color: #43a047;
	}
}

.icons-animation {
	transition: 0.3s !important;

	&:hover {
		transform: scale(1.2);
		color: $violet !important;
		path {
			fill: $violet;
		}
	}

	&.download {
		&:hover {
			path {
				fill: inherit !important;
				stroke: $violet !important;
			}
		}
	}
}

.preloader-pulse {
	animation: preloader-pulse 1s infinite;
}

.share-icon,
.download-icon {
	cursor: pointer;
}

a,
a:visited {
	color: #7d8c97;
	text-decoration: none;
}

.link {
	display: flex;

	a {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: $cyanLight;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.05em;

		svg {
			width: 20px;
			height: 20px;
			margin-left: 5px;
		}
	}
}

ul {
	padding: 0;
	margin: 0;
}

.about-container {
	.block-text {
		a {
			color: #2076d8;
			text-decoration: underline;
		}
	}
	li {
		position: relative;
		padding-left: 10px;

		&:after {
			content: "-";
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

button:focus {
	outline: 0;
}

.scrollbar1::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	background-color: rgb(66, 83, 97);
}
.scrollbar1::-webkit-scrollbar {
	width: 8px;
	background-color: rgb(66, 83, 97);
	border-radius: 8px;
}
.scrollbar1::-webkit-scrollbar-thumb {
	border-radius: 8px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: rgb(125, 140, 151);
}

.scrollbar2::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.1);
}
.scrollbar2::-webkit-scrollbar {
	width: 8px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}
.scrollbar2::-webkit-scrollbar-thumb {
	border-radius: 5px;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	background-color: rgba(0, 0, 0, 0.4);
}

.scrollbar-custom {
	&::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 8px;
		background-color: $grayLight;
	}

	&::-webkit-scrollbar {
		width: 15px;
	}
	&::-webkit-scrollbar-thumb {
		min-height: 50px;
		border: 4px solid transparent;
		background-clip: padding-box;
		border-radius: 15px;
		background-color: $gray;
		-webkit-box-shadow: inset 0px 1px 8px $gray;
		box-shadow: inset 0px 1px 8px $gray;
	}
	&::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}
	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

.preloading {
	display: flex;
	flex: 1;
	height: 100%;
	background-color: #334b5d;
	justify-content: center;
	align-items: center;

	#logo {
		animation: rotating 2s linear infinite;
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes preloader-pulse {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.5;
	}
}

//widget live-chat
#chat-widget-container {
	visibility: hidden !important;
}

body.dashboard #chat-widget-container {
	visibility: visible !important;
}

.grecaptcha-badge {
	visibility: hidden;
}

.start-page_step-1 {
	.grecaptcha-badge {
		visibility: visible;
	}
}

//Mobile styles
@media only screen and (max-width: 767px) {
	.mob-hide {
		display: none !important;
	}

	.mob-show {
		display: block !important;
	}

	.mob-pd-h-15 {
		padding-left: 15px !important;
		padding-right: 15px !important;
	}

	.mob-pd-h-20 {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.mob-pt-15 {
		padding-top: 15px !important;
	}
}

// TODO! The "intro-container' class used in several components. Remove after refactored.

.intro-container {
	min-height: 300px;
	background: var(--white);
	box-shadow: 0 12px 30px rgba(0, 26, 33, 0.3);
	border-radius: 12px;
	color: var(--blueDark);
	padding: 12px 30px 23px;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: space-between;
	align-items: center;

	@include desktop-up {
		width: 676px;
		min-height: 583px;
	}

	@include narrower-than(1280px) {
		width: 550px;
	}

	@include tablet-down {
		width: 100%;
	}

	> div {
		width: 100%;
	}

	.main-container-step3 {
		margin-bottom: auto;
	}

	.content {
		min-height: auto;
	}

	.block-text {
		text-align: center;
		margin-bottom: 20px;

		&.header {
			max-width: 440px;
			margin: 0 auto;
		}

		p {
			color: var(--gray);
			padding-top: 20px;
		}

		&.line {
			border-top: 1px solid var(--grayLight);
			margin: 30px 0 25px;

			p {
				background: var(--white);
				display: inline-block;
				padding: 0 21px;
				line-height: 1;
				margin-top: -9px;
			}
		}
	}

	.block-slider {
		max-width: 500px;
	}

	.block-btn {
		text-align: center;
	}
}

#portal {
	position: relative;
	z-index: 10000;
}
