@import "../../../assets/styles/media";

.key-concept-wrap-inner {
	line-height: 1.4;

	.accordion-item:not(:last-child) {
		margin-bottom: 20px;

		@include mobile-down {
			margin-bottom: 12px;
		}
	}

	h3 {
		text-align: left;
	}

	.list {
		margin-top: 12px;
		list-style-type: disc;
		padding-left: 14px;
	}
	.item {
		font-size: 13px;
		color: var(--text-color-secondary);
	}
}
