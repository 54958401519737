.vicri-container-external {
	max-width: 440px;
	box-shadow: none;

	:global {
		.vicri-header-container {
			min-height: 89px;
		}
		.vicri-footer-container {
			min-height: 243px;
		}
	}
}
