@import "../../../assets/styles/media";

.report-history {
	height: calc(100% - 35px);

	&.with-scroll {
		overflow-y: auto;
	}

	@include desktop-up {
		margin-top: 35px;
	}

	&.loading {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.2);
			border-radius: 10px;
			transition: 0.25s;
		}
	}

	.history-list {
		margin-top: 16px;
		padding-right: 10px;
	}

	.history-stub-wrap {
		padding: 0 20px;

		@include tablet-up {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		}
	}

	.history-text-stub {
		margin-top: 16px;
		text-align: center;
	}
}
