@import "../../assets/styles/colors";
@import "../../assets/styles/media";

.questions-wrap {
	width: 100%;

	h3 {
		color: $blueDark;
	}

	p {
		color: $gray;
		margin-top: 1rem;
	}

	h4 {
		font-size: 1.8rem;
		color: $blueDark;
	}

	.links-wrap {
		margin-top: 1.5rem;
	}

	.link {
		width: 100%;
		padding: 0.8rem 3.4rem 0.8rem 1.2rem;
		border-radius: 0.4rem;
		color: $blueDark;
		margin-bottom: 1.6rem;
		position: relative;

		img {
			position: absolute;
			right: 0.8rem;
		}
	}

	.note {
		background: #f8f9f9;
		border-radius: 0.4rem;
		padding: 1.2rem;
		font-size: 1.1rem;
		color: $gray;
		line-height: 1.4;
	}

	.btn-wrap {
		display: flex;
		justify-content: center;
		margin-top: 4rem;

		button {
			font-size: 1.6rem;
		}

		svg {
			font-size: 19px;
		}

		.btn-close {
			background: transparent;
			border: 1px solid $blueMediumDark;
			box-shadow: none;
			font-size: 1.4rem;
			padding: 0 3.2rem;

			svg {
				margin-right: 0.7rem;
			}
		}
	}

	.question-content {
		.form {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			margin-top: 3rem;

			@include tablet-up {
				height: 32rem;
			}

			> label {
				display: flex;
				width: 100%;
				margin: 0 0 1.6rem 0;

				@include tablet-up {
					max-width: 50%;
				}
			}

			.input-text-wrap {
				margin-bottom: 1.6rem;

				&:before,
				&:after {
					border-bottom: 1px solid $gray;
				}

				&:hover:before {
					border-color: $gray;
				}

				input {
					width: 100%;
					height: auto;
					box-sizing: border-box;
					border: none;
					padding: 0 0.8rem 0.8rem;
					font-size: 1.6rem;
					color: $blueMediumDark;

					&::placeholder {
						font-weight: 400;
						color: $grayLight;
						opacity: 1;
					}
				}
			}
		}

		&.question-3-content {
			.form {
				@include tablet-up {
					height: 18rem;
				}
			}

			.screen-2 {
				margin-top: 3rem;

				.sub-headline {
					margin-top: 2rem;
				}
			}
		}

		&.question-4-content {
			.form {
				@include tablet-up {
					height: 27rem;
				}
			}
		}

		&.question-5-content {
			.form {
				height: auto;
				flex-wrap: nowrap;
			}

			.input-slider-wrap {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 100%;
			}

			.input-slider-label {
				min-width: 18rem;
				font-size: 1.6rem;
				color: $blueDark;
				line-height: 1.4;
				margin: 0 2rem 0 0;
			}

			.input-slider-container {
				margin: 0;
			}

			.input-slider-text-wrap {
				min-width: 4.8rem;
				padding: 0;

				@include mobile-down {
					min-width: auto;
				}

				input {
					width: 100%;
					font-size: 1.6rem;
					line-height: 1.4;
					text-align: center;
					padding-top: 0;
					height: auto;
					padding-bottom: 1rem;
				}
			}

			.slider-wrap {
				height: 3.1rem;
				padding: 0;
				position: relative;
				margin-left: 2rem;

				&:before {
					content: url(../../../images/close_icon_v2.svg);
					position: absolute;
					top: -0.1rem;
					left: 0.3rem;
				}

				&:after {
					content: url(../../../images/check_icon.svg);
					position: absolute;
					top: -0.1rem;
					right: 0;
				}

				@include mobile-down {
					flex-basis: 100%;
					margin: 0 0 3rem;
					padding: 0 2rem;

					&:before,
					&:after {
						top: 1.1rem;
					}

					.slider {
						top: 0;
						width: calc(100% - 4rem);
					}
				}
			}
		}

		&.question-6-content {
			.form {
				@include tablet-up {
					height: 27rem;
				}
			}

			.screen-2 {
				margin-top: 3rem;

				.sub-headline {
					margin-top: 2rem;
				}
			}
		}

		&.question-7-content {
			.form {
				@include tablet-up {
					height: 3rem;
				}

				.input-root {
					width: 100%;
				}
			}
		}
	}
}
