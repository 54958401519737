.page-tests-container {
	margin-top: 20px;
}

.content-wrap {
	max-width: 1060px;
	width: 100%;
	margin: 20px auto;
}

.btn-wrap {
	display: flex;
	align-items: center;
	gap: 15px;
	margin: 15px 0;
}
