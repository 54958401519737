.modal-content {
	background: var(--background-color-secondary);
	position: relative;
	z-index: 10;
}

.container {
	height: 100%;
	overflow: hidden auto;
	padding: 30px 40px;
	color: var(--text-color-primary);

	.content {
		&:before,
		&:after {
			content: "";
			height: 50px;
			position: absolute;
			left: 40px;
			right: 40px;
			z-index: 10;
		}

		&:before {
			top: 0;
			background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
		}

		&:after {
			bottom: 0;
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
		}
	}

	.header {
		padding-top: 10px;
		border-bottom: 1px solid #dee2e5;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	.body {
		color: var(--text-color-secondary);
		font-size: 13px;
		padding-top: 10px;

		p,
		ul {
			margin-bottom: 20px;
		}

		li {
			padding-left: 10px;
			position: relative;

			&:after {
				content: "-";
				left: 0;
				position: absolute;
				top: 0;
			}
		}
	}

	a {
		color: #2076d8;
		text-decoration: underline;
		word-wrap: break-word;
	}
}
