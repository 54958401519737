@import "../../assets/styles/media";

.company-members-wrap {

	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: column;
	color: var(--blueDark);

	&.load {
		opacity: 0.1;
	}

	.header {
		padding: 20px;
		font-size: 40px;
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}

	.report-history {
		overflow: auto;
		height: auto;
		width: 100%;
		margin: 0;

		h3 {
			font-size: 32px;
			line-height: 136%;
		}

		h4 {
			font-size: 16px;
			line-height: 140%;
			color: var(--textGray);
		}
	}
}
