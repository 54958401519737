@import "../../assets/styles/media";

.traveling-soon {
	background: var(--violet) url("./images/TravelingSoon.jpg");
	background-size: cover;
	background-position: center center;
	min-height: 568px;

	@include tablet-down {
		min-height: auto;
		padding-bottom: 50px;
	}

	.header {
		max-width: 683px;
		color: var(--text-color-tertiary);

		@include mobile-down {
			text-align: left;
		}

		:global {
			.h2 {
				line-height: 1.4;
				margin-top: 25px;

				@include mobile-down {
					display: flex;
					align-items: center;
					line-height: 1.1;

					&::before {
						content: "flight";
						display: inline-block;
						font-family: "Material Icons";
						font-size: 60px;
						color: var(--text-color-tertiary);
						transform: rotate(45deg);
						margin-top: -5px;
					}
				}
			}

			.sub-title {
				color: var(--text-color-tertiary);
				margin-top: 21px;
			}
		}
	}

	.row-1 {
		text-align: center;
		padding-top: 60px;

		@include tablet-down {
			padding-top: 50px;
		}

		@include mobile-down {
			display: none;
		}
	}

	.icon-airplane {
		font-size: 60px;
		color: var(--text-color-tertiary);
		transform: rotate(45deg);
	}

	.row-2 {
		display: flex;
		justify-content: center;
	}

	.row-3 {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		margin-top: 29px;

		@include tablet-down {
			flex-direction: column;
			align-items: center;
			gap: 20px;
		}
	}
}
