@import "../../assets/styles/colors";

.tips-container {
	margin-top: 20px;

	.box-tips {
		display: flex;
		align-items: center;
		min-height: 100px;
		border-radius: 10px;
		padding: 17px 50px 17px 25px;
		position: relative;

		.content-wrap {
			display: flex;
		}

		.block-text {
			display: flex;
			flex-direction: column;
			margin-left: 20px;
			justify-content: space-between;
			color: var(--text-color-primary);

			h3 {
				color: var(--text-color-primary);
			}

			span {
				font-size: 13px;
				color: var(--text-color-primary);
			}

			a {
				max-width: max-content;
				text-decoration: underline;
			}

			.notification-url {
				&:hover,
				span:hover {
					color: var(--violet);
					transition: 0.25s;
				}
			}
		}
	}

	&.tips-container-variant-2 {
		margin-top: 0;

		&.read-hide {
			height: 0;
			overflow: hidden;
			transition: 1s;
		}

		.box-tips {
			min-height: auto;
			align-items: flex-start;
			padding: 15px 22px 13px 22px;
			justify-content: space-between;

			&.box-tips-alert {
				.block-icon {
					margin-top: 3px;
				}
			}
		}

		.block-icon {
			align-self: flex-start;
		}

		.block-text {
			margin: 3px 0 0 10px;

			span {
				color: var(--text-color-secondary);
				margin-top: 4px;
			}
		}

		.block-btn {
			align-self: center;
			margin-left: 10px;

			button {
				min-width: 80px;
				height: 30px;
				display: flex;
				align-items: center;
				border: none;
				border-radius: 50px;
				padding: 8px 10px 6px 7px;
				font-size: 12px;
				color: var(--violet);
				font-weight: 700;
				line-height: 1;
				text-transform: uppercase;
				letter-spacing: 0.5px;
				cursor: pointer;
				transition: 0.25s;

				&:hover {
					box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
					transition: 0.25s;
				}
			}

			:global {
				.bg-red {
					color: red;
				}
			}

			.btn-icon {
				font-size: 24px;
				font-variation-settings: "wght" 500;
			}

			.btn-text {
				margin-left: 2px;
			}
		}
	}

	&.tips-container-question {
		.block-icon {
			align-self: center;
		}

		.block-btn {
			min-width: 275px;
			display: flex;
		}
	}
}

@media only screen and (max-width: 767px) {
	.tips-container {
		&.tips-container-variant-2 {
			.block-btn {
				align-self: flex-start;
				.btn-icon {
					display: flex;
				}
				button {
					padding: 5px;
					min-width: auto;
				}
			}
		}

		&.tips-container-question {
			.box-tips {
				flex-direction: column;
			}
			.block-btn {
				margin: 15px 0 0 34px;
			}
		}
	}
}

@media only screen and (max-width: 479px) {
	.tips-container {
		&.tips-container-question {
			.block-btn {
				flex-direction: column;
				min-width: auto;

				button:last-of-type {
					margin-top: 10px;
				}
			}
		}
	}
}
