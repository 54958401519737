.accordion {
	// max-width: 768px;
	// max-height: 600px;
	// height: 600px; //##
	list-style: none;
	margin-top: 12px;

	.accordion-item:last-of-type {
		display: none;
	}
}
