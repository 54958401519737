@import "../../../assets/styles/media";

.most-popular-destinations {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	font-size: 13px;

	.description {
		font-weight: 400;
		color: var(--blueDark);
	}
	.examples {
		display: flex;
		flex-wrap: nowrap;
		.example {
			color: var(--violet);
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
