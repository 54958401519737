@import "../assets/styles/colors";

.close-container {
	position: relative;
	margin: auto;
	width: 30px;
	height: 30px;
	cursor: pointer;

	&:hover {
		.item-1 {
			transform: rotate(-45deg);
			background-color: $blueDark;
		}

		.item-2 {
			transform: rotate(45deg);
			background-color: $blueDark;
		}
	}

	.item-1 {
		height: 1.74px;
		width: 30px;
		position: absolute;
		margin-top: 15px;
		background-color: $gray;
		border-radius: 2px;
		transform: rotate(45deg);
		transition: all 0.3s ease-in;
	}

	.item-2 {
		height: 1.74px;
		width: 30px;
		position: absolute;
		margin-top: 15px;
		background-color: $gray;
		border-radius: 2px;
		transform: rotate(-45deg);
		transition: all 0.3s ease-in;
	}
}
