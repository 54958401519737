@import "../../assets/styles/media";

.personalized-risk-score {
	height: 431px;
	background: var(--background-color-secondary);

	@include tablet-down {
		height: auto;
		padding-bottom: 50px;
	}

	@include mobile-down {
		background: transparent;
		margin-top: -545px;
	}

	.header {
		:global {
			.sub-title {
				line-height: 1.4;
				margin-top: 12px;

				@include mobile-down {
					color: var(--text-color-tertiary);
					margin-top: 30px;
				}
			}
		}

		@include mobile-down {
			color: var(--text-color-tertiary);
		}
	}

	.content {
		display: flex;
		gap: 117px;

		@include tablet-down {
			flex-direction: row-reverse;
			gap: 36px;
		}

		@include mobile-down {
			flex-direction: column-reverse;
			gap: 52px;
			align-items: center;
		}
	}

	.col-1 {
		max-width: 370px;
		width: 100%;
		margin-top: -100px;

		@include tablet-down {
			margin-top: 50px;
		}

		@include mobile-down {
			max-width: 350px;
			margin-top: 0;
		}
	}

	.col-2 {
		max-width: 632px;
		padding-top: 59px;

		@include tablet-down {
			max-width: 675px;
			padding-top: 50px;
			width: 100%;
		}

		@include mobile-down {
			max-width: none;
		}
	}
}
