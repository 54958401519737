:global {
	.risk-safety-summary-container {
		:local {
			.locations-demo {
				margin-top: 16px;
				padding-bottom: 16px;

				.contacts-wrap {
					margin-top: 12px;
					display: flex;
					flex-direction: column;
					gap: 12px;
				}
			}
		}
	}
}
