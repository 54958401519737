@import "../../../assets/styles/media";

.violent-crimer-risk-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	// TODO: need to check
	// padding: 24px 20px 45px;
	position: relative;
	opacity: 0;
	transition: opacity 2s ease;

	@include mobile-down {
		max-width: 495px;
	}

	&.visible {
		opacity: 1;
	}
}

.block-info-wrap {
	position: absolute;
	bottom: 0;
	right: 0;
	max-width: 229px;
	width: 100%;
	height: 40px;
	background: var(--gray);
	padding: 10px 5px 10px 10px;
	border-radius: 8px 0 8px 0;
	color: #f8f9f9;
}

.block-info-icon {
	font-variation-settings:
		"FILL" 1,
		"wght" 400,
		"GRAD" 0,
		"opsz" 48;
	margin-right: 8px;
}

.header {
	display: flex;
	justify-content: space-between;
}

.block-text {
	display: flex;
}

.tip-wrap {
	display: inline-block;
}

.block-info-icon {
	color: #7d8c97;
	cursor: pointer;
	margin: 1px 0 0 5px !important;

	&:hover {
		color: var(--violet);
	}
}

.sub-line {
	display: inline-block;
	font-size: 13px;
	color: var(--gray);
	line-height: 1.4;
	vertical-align: baseline;
}

.graph-container {
	display: flex;
	height: 100%;
}

.graph-wrap {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.left-box {
	// TODO: need to check
	// margin-top: 22px;
}

.similar-cities-content {
	display: flex;
	margin-top: 18px;

	:global {
		.similar-cities {
			box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		}
	}
}

.right-box {
	height: 100%;
	display: flex;
	align-items: center;
}

.graph-top {
	width: 450px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.violent-crimer-risk-container {
		justify-content: center;
	}
}

:global {
	.value-scale {
		opacity: 0;

		&.active {
			opacity: 1;
		}
	}
}

.block-text-index {
	font-size: 18px;
	color: var(--blueDark);
	margin-top: -65px;
}

.block-text-location {
	max-width: 55%;
	width: 100%;
	min-height: 22px;
	font-size: 24px;
	line-height: 1.2;
	color: var(--blueDark);
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mobile {
	max-width: none;
	display: none;
}

.block-text-state {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 13px;
	color: var(--gray);
	padding: 5px 23px;
	margin-top: 7px;
}

.graph-wrap-conflict-destination,
.graph-wrap-nodata-destination {
	height: 100%;

	.graph-top {
		height: 100%;
	}

	@media only screen and (min-width: 1399px) {
		.graph-top {
			max-width: 333px;
			margin: -36px 70px 0 0;
		}
	}

	:global {
		.violent-crimer-risk-container {
			box-shadow: none;
			background: transparent;

			.block-info-top-wrap {
				display: none;
			}
		}
	}
}

@media only screen and (max-width: 1399px) {
	.block-text-state {
		margin-top: 0px;
		padding-left: 12px;
		padding-right: 12px;
	}
}

@media only screen and (max-width: 1023px) {
	.right-box {
		max-width: 300px;
		width: 100%;
	}

	.graph-top {
		width: 100%;
	}

	.similar-cities-content {
		flex-direction: column;
	}

	:global {
		.similar-cities-right {
			margin: 15px 0 0;
		}
	}
}

@media only screen and (max-width: 767px) {
	.graph-wrap {
		flex-direction: column-reverse;
		align-items: center;
	}

	.left-box {
		margin-top: 0;
		width: 100%;
	}

	.similar-cities-home {
		margin-top: 25px;
	}

	:global {
		.similar-cities-info-text {
			width: 100%;

			p {
				max-width: none;
				padding-right: 20px;
			}
		}
	}

	.right-box {
		margin-top: 25px;
	}

	.block-text-state {
		padding-left: 0;
		padding-right: 0;
	}

	.block-text-index {
		margin-top: -45px !important;
	}

	.block-text-location {
		font-size: 16px;
	}

	.desktop {
		display: none !important;
	}

	.mobile {
		display: block !important;
	}
}

.preloader-section {
	box-shadow: none;
}

.preloader-text-block {
	color: transparent;
	background-color: #d9d9d9;
	border-radius: 2px;
	opacity: 0.5;
	user-select: none;
	:global {
		animation: preloader-pulse 1s infinite;
	}
}
