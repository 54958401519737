@import "../assets/styles/colors";

.graph-color-icon {
	width: 16px;
	height: 16px;
	border-radius: 5px;
	margin-right: 4px;

	&.graph-color-violet {
		background: $violet;
	}

	&.graph-color-health {
		background: $health;
	}

	&.graph-color-crime {
		background: $crime;
	}

	&.graph-color-safety {
		background: $cyanLight;
	}

	&.graph-color-conflict {
		// background: repeating-linear-gradient(45deg, #fff, #fff 10px, #f8f9f9 10px, #f8f9f9 20px);
		background: repeating-linear-gradient(45deg, #fff, #fff 2px, #ccc 2px, #ccc 4px);
	}
}
