@import "../../assets/styles/media";

.body {
	&:global(.react-tooltip) {
		max-width: 258px;
		background-color: rgba(35, 53, 68, 1);
		z-index: 2;
		color: #fff;
		padding: 10px;
		font-size: 11px;
		line-height: 14px;
		border-radius: 3px;

		a {
			color: var(--cyanLight);
		}
	}
}
