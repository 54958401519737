body > iframe[style*="2147483647"] {
	display: none;
}

.external-form-container {
	max-width: 1100px;
	margin: 0 auto;
	padding: 30px 20px;

	.traveller-info-container {
		margin-top: 30px;
		opacity: 0;
		transform: scaleY(0.8);
	}

	.traveller-info-container-done-enter {
		opacity: 1;
		transform: scaleY(1);
		transition: 0.23s;
	}
}
