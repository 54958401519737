.btn-wrap {
	display: flex;
	gap: 10px;
}

.btn-store {
	a {
		display: inline-block;
	}

	img {
		height: 100%;
	}
}
