.risk-highlights-indicator-scale {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 4px;
	margin: 4px 0 12px;
}
.scale-item {
	width: 19.6%;
	border-radius: 6px;
	background-color: var(--colorRiskDefault);
}

.bg-color-risk-1 {
	background-color: var(--colorRisk1);
}

.bg-color-risk-2 {
	background-color: var(--colorRisk2);
}

.bg-color-risk-3 {
	background-color: var(--colorRisk3);
}

.bg-color-risk-4 {
	background-color: var(--colorRisk4);
}

.bg-color-risk-5 {
	background-color: var(--colorRisk5);
}
