@import "../../assets/styles/media";

.form-inner {
	.form-container {
		width: 100%;
		margin: 0 auto 0px;
	}

	.field-wrap {
		position: relative;

		&.field-wrap-password {
			.field-help-text {
				position: static;
				margin: -16px 0 10px;
			}
		}

		.field-error,
		.field-help-text {
			position: static;
			margin: -16px 0 10px;
			top: 50px;
			font-size: 11px;
			line-height: 1;
			text-align: left;
		}

		.field-error {
			color: red;
		}

		::placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		::-webkit-input-placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		::-moz-placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		input:-moz-placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-text-fill-color: var(--blueDark) !important;
		}

		input:-internal-autofill-selected {
			background-color: white !important;
		}

		label {
			color: var(--gray) !important;
			font-size: 1.2rem;
		}
	}

	.field-wrap-link-forgot {
		position: absolute;
		width: 100%;
		margin-top: -15px;
		font-size: 12px;
		color: var(--text-link-color-primary);

		text-align: right;
		text-decoration: underline;

		span {
			cursor: pointer;
		}
	}

	.field-wrap-help-password {
		font-size: 11px;
		line-height: 1.2;
	}

	.field-wrap-btn {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 22px;

		.button {
			&.variant-filled {
				&.color-primary {

				}

				&.color-secondary {
					&:hover:not(:active):not(.disabled) {
						background: var(--button-background-color-primary);
						color: var(--button-text-color-primary);
					}
				}
			}

			width: 100%;
			height: 38px;
		}

		span {
			color: var(--white);
		}

		.btn-cancel {
			margin-top: 15px;
		}

		.pulse-text {
			transform: scale(1);
			animation: pulse 1s infinite;
		}

		@keyframes pulse {
			0% {
				transform: scale(0.95);
			}

			70% {
				transform: scale(1);
			}

			100% {
				transform: scale(0.95);
			}
		}
	}
}

:global {
	@keyframes caret-blink {
		0%,70%,to {
				opacity: 1
		}

		20%,50% {
				opacity: 0
		}
	}

	.step4 {
		input {
			color: var(--gray) !important;
		}
	}

	.get-register-steps-container {

		.form-password {
			.email-field::after {
				content: url(../../../images/check_icon_v2.svg);
				position: absolute;
				top: 1.6rem;
				right: 0;
			}
		}

		.form-account-ready {
			.learn-more-btn button {
				border-color: var(--border-color-primary)§;
				background-color: var(--white);

				span {
					color: var(--newLink);
				}
			}

			.title-box {
					margin: 0 21px;
			}

			.top-text {
				color: var(--gray);
				padding: 10px;
				text-align: start;
			}

			.list {
				color: var(--gray);
				padding: 10px;
				text-align: start;

				li::before {
					content: url(../../../images/check_icon_v3.svg);
					top: 0.4rem;
					right: 0;
					padding-right: 5px;
					padding-left: 0;
					position: relative;
				}
			}

			.button-text {
				color: var(--gray);
				padding: 10px;
				text-align: start;
			}
		}

		.select-gender:hover .info-lgbtqi {
			z-index: 1;
		}

		.select-field {
			display: flex;
			flex-direction: column;
			width: 100%;

			p {
				padding-top: 5px;
			}

			label {
				display: flex;
				justify-content: flex-start;
				padding: 5px 0;

				> div {
					position: absolute;
					right: 23px;
					top: 25px;
					// z-index: 1;

					.material-icons {
						font-size: 16px;
					}
				}
			}

			> div {
				width: 100%;
				display: flex;
				.MuiInput-root {
					position: relative;
					width: 100%;
					color: var(--gray);
					margin-bottom: 20px;

					.MuiSelect-select {
						width: 100%;
						text-align: start;
					}
				}
			}
		}
	}

	.get-started-steps-container, .get-register-steps-container {
		text-align: center;
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: 400px;
		flex: 1;
		justify-content: space-between;
		padding: 0 140px;
		justify-content: center;

		.form-sign-in {
			.title-box {
				justify-content: center;
				font-size: 40px;
			}
		}

		&.addition-steps-container {
			min-height: 470px;
		}

		&.addition-steps-container {
			min-height: 470px;
		}

		.otp-wrapper {
			display: flex;
			margin-top: 36px;
			margin-bottom: 40px;

			.resend-btn {
				text-decoration: underline;
				font-size: 10px;
				display: flex;
				align-items: flex-end;
				width: 100%;
				min-width: 80px;
				justify-content: flex-end;
				color: var(--text-link-color-primary);
			}
		}

		.otp-container {
			position: relative;
			cursor: text;
			user-select: none;
			pointer-events: none;
			width: 100%;
			height: 100%;

			.flex {
				display: flex;
				flex-direction: row;
				gap: 8px;
			}

			.slot {
				border: 1px solid var(--gray);
				display: flex;
				align-items: center;
				justify-content: center;
				transition-property: all;
				transition-timing-function: cubic-bezier(.4,0,.2,1);
				transition-duration: .3s;
				height: 40px;
				width: 32px;
				border-radius: 6px;

				&.active {
					border-color: var(--border-color-primary-hover);
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					transition-property: all;
					transition-timing-function: cubic-bezier(.4,0,.2,1);
					transition-duration: .3s;
				}
			}

			.fake-caret {
				animation: caret-blink 1.2s ease-out infinite;

				.fake-caret-elm {
					background-color: var(--dark);
					height: 20px;
					width: .125rem;
				}
			}
		}

		.header {
			margin-top: 30px;
			margin-bottom: 20px;
			max-width: 440px;
			margin-left: auto;
			margin-right: auto;

			.title-box {
				display: flex;
				gap: 5px;
			}

			p {
				color: var(--gray);
				margin-top: 13px;
			}

			&.thank {
				.link {
					justify-content: center;
					margin-top: 20px;
				}
			}
		}

		&.block-login {
			justify-content: center;
			.header {
				margin-bottom: 13px;
			}

			h3 {
				margin-bottom: 15px;
			}
		}

		.form-container {
			width: 100%;
			margin: 0 auto 0px;

			&.form-auth-container {
				margin-top: 44px;

				.field-wrap-location {
					.label {
						color: var(--gray);
						font-size: 1.2rem;
					}

					p {
						position: static;
						padding-top: 5px;
					}

					.MuiFormControl-root {
						>div >div {
							background-color: var(--background-color-primary);

							&:before {
								left: 0;
								right: 0;
								bottom: 0;
								content: "\00a0";
								position: absolute;
								transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
								border-bottom: 2px solid #DEE2E5 !important;
								pointer-events: none;
							}

							>div >div{
								color: var(--gray);
								text-align: start;
								font-weight: normal;

								padding: 0 1px 10px;
								font-size: 16px;
							}
						}
					}
				}
			}
		}

		.text-auth {
			display: flex;
			align-items: center;
			text-align: center;
			color: var(--gray);
			margin-top: 44px;
		}

		.text-auth::before,
		.text-auth::after {
			content: "";
			flex: 1;
			border-bottom: 1px solid #D9D9D9;
			margin: 0 10px;
		}

		.btn-auth-container {
			display: flex;
			margin: 0 auto;
			margin-top: 44px;
			margin-bottom: 10px;
			gap: 5px;

			@include mobile-down {
				min-width: 220px;
				flex-direction: column;
				display: flex;
				align-items: center;
			}

			button,
			.button {
				box-shadow: none;
				width: 100%;
				width: 200px;
				margin-bottom: 10px;
				transition: box-shadow 250ms;

				&:hover {
					opacity: 1 !important;
					box-shadow:
						0px 2px 4px -1px #0003,
						0px 4px 5px 0px #00000024,
						0px 1px 10px 0px #0000001f !important;
				}
			}
		}

		.text-terms {
			font-size: 12px;
			color: var(--gray);
			margin-top: 30px;
			margin-bottom: 30px;

			a {
				text-decoration: underline;
				text-decoration-style: solid;
			}
		}

		.block-text.line {
			margin-top: 10px;
		}

		.label-skip-login {
			font-size: 13px;
			color: var(--gray);
		}

		.block-btn-skip {
			font-size: 13px;
			text-decoration: underline;
			cursor: pointer;
			margin-top: 3px;
		}

		.block-checkbox {
			margin-top: 39px;

			label {
				border-top: 1px solid var(--grayLight);
			}
		}

		&.block-step-final {
			max-width: 440px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
			padding-top: 30px;

			img {
				width: 60px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.block-btn {
			&.change-destination {
				display: flex;
				flex-direction: column;
				justify-content: center;

				@include desktop-up {
					flex-direction: row-reverse;
				}

				.btn {
					margin: 20px 10px 0;
				}
			}

			button {
				min-width: 242px;

				@include mobile-down {
					min-width: 220px;
				}
			}
		}

		.fields-container {
			max-width: 245px;
			width: 100%;
			margin: 0 auto;

			&-pos-center {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
			}

			.name-wrap,
			.email-pass-wrap {
				.field-inner {
					text-align: left;
				}
			}
		}

		.report-limit-wrap {
			margin-top: 30px;

			.text {
				font-size: 12px;
				margin-top: 4px;
			}
		}

		.form-forgot-container {
			margin-top: 50px;
		}
	}

	.title-wrap {
		display: flex;
		justify-content: center;
		align-items: center;

		.location {
			max-width: 260px;
			width: 100%;
			text-align: left;

			&-home {
				text-align: right;
			}

			.icon {
				margin-right: 10px;
				vertical-align: middle;
			}
		}

		.icon-compare {
			color: var(--gray);
			margin: 0 14px;
		}
	}

	@include bigdesktop-down {
		.title-wrap {
			flex-direction: column;

			.location {
				max-width: none;
				text-align: center;
			}
		}
	}

	@include mobile-down {
		.get-started-steps-container, .get-register-steps-container {
			min-height: auto;
			height: 100%;
			justify-content: space-between;
			overflow-y: auto;
			padding: 0 10px;

			&.block-login {
				justify-content: normal;

				.title-box {
					font-size: 32px;
				}
			}

			.indicators-dots {
				top: 0;
			}

			.header {
				margin-top: 20px 0 !important;
				margin-bottom: 20px 0 !important;

				h2 {
					font-size: 18px;
				}

				p {
					font-size: 13px !important;
					margin-top: 0;
					max-width: 360px;
				}
			}

			.text-terms {
				margin-top: 0;
			}
		}
	}
}
