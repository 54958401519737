.header {
	.row-1 {
		display: flex;
		justify-content: space-between;
		margin-bottom: 6px;
	}
}

.sub-line {
	display: inline-block;
	font-size: 13px;
	color: var(--text-color-secondary);
	line-height: 1.4;
}

.logo-wrap {
	display: flex;
	align-items: center;
	gap: 8px;
}

.text {
	font-size: 11px;
	line-height: 1.4;
}

.link {
	line-height: 1;
}
.logo {
	max-width: 65px;

	:global {
		.logo-text {
			fill: var(--text-color-primary);
		}
	}
}
