@import "../../../assets/styles/colors";

.react-player-wrapper {
	position: relative;
	height: 100%;
	display: flex;
	justify-content: center;
}

.react-player-preview-video {
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;

	&:before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.2);
		transition: 0.25s;
	}

	&:not(.react-player-no-video):hover:before {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.custom-play-button {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	padding: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 0;
	background-color: $cyanLight;
	cursor: pointer;
}

.play-arrow {
	font-size: 26px;
	color: $blueDark;
	font-variation-settings:
		"FILL" 0,
		"wght" 500,
		"GRAD" -25,
		"opsz" 48;
}
