.plan {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 16px;
	width: 100%;
	// height: 176px;
	color: var(--blueDark);
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);

	.title {
		font-weight: bold;
	}

	.stats {
		width: 100%;

		.info {
			color: var(--textGray);
			display: flex;
			justify-content: space-between;
		}

		.count {
			// font-weight: bold;
		}

		.bar {
			width: 100%;
			background: linear-gradient(to right, var(--violet) 0%, var(--grayLight) 100%);
			transition: background 0.3s ease;
			height: 10px;
			border-radius: 2px;
		}
	}

	.button-wrap {
		width: 100%;
	}
}