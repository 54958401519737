@import "../../assets/styles/colors";

.user-personal-data-wrap {
	max-width: 32.3rem;
	width: 100%;
	margin-top: 3.5rem;

	.field-error {
		min-height: 2.2rem;
		font-size: 1.4rem;
		color: $crime;
		text-align: center;
	}

	.user-personal-data-text {
		font-size: 1.3rem;
		color: $gray;
		line-height: 1.4;
		margin-top: 2rem;
	}

	.user-personal-data-title {
		color: $blueDark;
	}

	.user-personal-data-body {
		margin-top: 3.2rem;
	}

	.field-wrap {
		border-bottom: 1px solid $grayLight;
		padding-top: 0.4rem;
		position: relative;

		&.field-wrap-personal {
			.field-inner {
				padding-top: 1.1rem;
				cursor: pointer;

				* {
					cursor: pointer;
					color: $blueMediumDark;
				}
			}

			.label {
				display: flex;
				gap: 5px;
				font-size: 13px;
				color: $gray;
			}

			&.field-wrap-location {
				.field-inner {
					padding-top: 0;
				}

				.label {
					position: relative;
					bottom: -7px;
				}
			}

			.MuiInputBase-root {
				width: 100%;

				&:before,
				&:after {
					border: none;
				}
			}
		}

		.MuiTextField-root {
			height: 2.2rem;
		}

		.input-wrap {
			padding-right: 3rem;

			::placeholder {
				color: $grayLight !important;
				opacity: 1 !important;
			}

			::-webkit-input-placeholder {
				color: $grayLight !important;
				opacity: 1 !important;
			}

			::-moz-placeholder {
				color: $grayLight !important;
				opacity: 1 !important;
			}

			input:-moz-placeholder {
				color: $grayLight !important;
				opacity: 1 !important;
			}

			input {
				color: $blueDark !important;
			}
		}

		.btn {
			display: flex;
			align-items: center;
			position: absolute;
			top: 50%;
			right: 0;
			cursor: pointer;
			transform: translateY(-50%);
		}

		.help-text {
			font-size: 1.3rem;
			color: $grayLight;
		}

		&.field-active {
			.help-text {
				color: $gray;
			}
		}
	}

	.submit-edit {
		max-width: 182px;
		display: flex;
		justify-content: center;
		margin: 7.5rem auto 3.5rem;
	}
}
