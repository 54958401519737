@import "../../assets/styles/media";

.page-confirmed-email {
	.content {
		height: 100%;
		justify-content: space-between;
	}

	.body {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include tablet-up {
			flex-direction: row-reverse;
			align-items: center;
		}

		.block-text {
			width: 100%;

			@include tablet-up {
				max-width: 440px;
			}

			.title {
				font-size: 26px;
				color: var(--text-color-primary);
				line-height: 43.52px;

				@include tablet-up {
					font-size: 32px;
				}
			}

			.description {
				font-size: 18px;
				color: var(--text-color-secondary);
				line-height: 23px;
				margin-top: 20px;

				@include tablet-up {
					font-size: 20px;
					line-height: 25px;
				}
			}

			.btn-wrap {
				margin-top: 20px;
			}
		}
	}

	.block-img {
		@include mobile-down {
			display: flex;
			justify-content: center;
			margin-top: 51px;
		}

		img {
			width: 100%;
			max-width: 440px;

			@include tablet-up {
				width: 440px;
			}
		}
	}

	.footer {
		@include mobile-down {
			margin-top: 56px;
		}

		:global {
			.footer-content {
				@include mobile-down {
					padding: 0;
					margin-bottom: 0;
				}
			}
		}
	}
}
