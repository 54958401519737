@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

.locations-content {
	.location {
		display: flex;

		&-icon {
			font-size: 20px;
			color: $blueDark;
			margin-right: 4px;
		}
	}

	.spacer {
		height: 48px;
		background: url("../../../../images/vertical_dotted_line.png") repeat-y;
		margin-left: 9px;
	}
}
