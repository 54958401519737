@import "../../../assets/styles/media";

.rating-general-text {
	font-size: 16px;
	font-weight: 400;

	@include mobile-down {
		margin: 6px 0;
	}
}
