.container {
	display: flex;
	align-items: center;
	gap: 5px;
	padding: 5px;
	border-radius: 5px;
	position: relative;

	&:hover {
		cursor: pointer;
		background-color: var(--grayLight);
	}

	.avatar {
		width: 40px;
		height: 40px;
		border-radius: 10px;
		border: 2px solid white;
		background-size: auto 100%;
		background-position: center;
		box-shadow: 0px 1px 4px 0px rgba(59, 61, 107, 0.25);
	}

	.name {
		color: var(--blueDark);
		font-size: 16px;
		font-weight: 400;
		display: inline-block;
		max-width: 150px;
		user-select: none;
	}

	.logo {
		max-height: 26px;
	}

	.arrow {
		font-size: 24px;
	}
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.2);
	z-index: 998;
}

.dropdown-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 999;
}