@import "../assets/styles/colorConstants";

.wrapper {
	position: relative;
	top: -10px;

	:global {
		.rti--container, .rti--container:focus-within {
			border: none;
			box-shadow: none;
		}

		.rti--tag {
			background: val(--grayLight);
			color: val(--blueDark);
		}

		.rti--input {
			width: 100%;
			border-bottom: 1px solid #ccc;
			color: var(--gray);

			&:disabled {
				background: unset;
			}
		}
	}

	.label {
		font-size: 12px;
		.text {
			color: var(--gray);
		}
	}
}
