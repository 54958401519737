@import "../../assets/styles/media";

.critical-safety-indicators {
	background: url("./images/safetyBg.png"), linear-gradient(288deg, #10a9b0 1.37%, #16937a 58.41%, #92ca80 100.32%);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding-bottom: 50px;

	@include bigdesktop-up {
		height: 1024px;
	}

	.header {
		color: var(--text-color-tertiary);

		:global {
			.h2 {
				line-height: 1.5;

				@include mobile-down {
					line-height: 1.1;
				}
			}
		}
	}

	.content {
		display: flex;
		position: relative;

		@include tablet-down {
			flex-direction: column;
		}
	}

	.col-1 {
		max-width: 466px;
		width: 100%;
		padding-top: 200px;

		@include desktop-down {
			padding-top: 50px;
			max-width: 50%;
		}

		@include tablet-down {
			max-width: none;
		}
	}

	.collage {
		width: 100%;
		position: relative;
		margin-top: 200px;

		@include bigdesktop-down {
			transform: scale(0.6);
			margin-top: 100px;
		}

		@include tablet-down {
			transform: scale(1);
			margin-top: 20px;
			width: calc(100% + 80px);
			margin-left: -40px;
			margin-right: -40px;
			max-width: none;
		}

		@include mobile-down {
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-right: -20px;
		}

		:global {
			.slick-slider {
				height: 36vw;

				@include mobile-down {
					height: 38vw;
				}

				@include narrower-than(640px) {
					height: 70vw;
				}

				@include narrower-than(580px) {
					height: 65vw;
				}

				@include narrower-than(480px) {
					height: 73vw;
				}

				@include narrower-than(390px) {
					height: 78vw;
				}
			}
		}
	}

	.img {
		box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
		border-radius: 7px;

		@include desktop-up {
			position: absolute;
		}
	}

	@include desktop-up {
		.img-small {
			width: 195px;
			opacity: 0.8;
		}

		.img-middle {
			width: 211px;
			opacity: 0.85;
			z-index: 10;
		}

		.img-large {
			width: 297px;
			z-index: 20;
			transform: perspective(700px) rotateY(-15deg);
		}

		.img-r1-1 {
			top: 121px;
			left: 16px;
		}

		.img-r1-2 {
			top: 61px;
			left: 196px;
		}

		.img-r1-3 {
			top: 121px;
			left: 391px;
		}

		.img-r1-4 {
			top: 272px;
			left: 16px;
		}

		.img-r1-5 {
			top: 231px;
			left: 196px;
		}

		.img-r1-6 {
			top: 272px;
			left: 391px;
		}

		.img-r2-1 {
			top: 361px;
			left: -114px;
			z-index: 30;
		}

		.img-r2-2 {
			top: 361px;
			left: 155px;
			z-index: 25;
		}

		.img-r2-3 {
			top: 361px;
			left: 425px;
		}
	}

	.list-wrap {
		max-width: 249px;
		color: var(--text-color-tertiary);
		margin-top: 30px;

		@include tablet-down {
			max-width: none;
		}
	}

	.list {
		margin-top: 35px;
		list-style-type: disc;
		padding-left: 37px;
	}
}
