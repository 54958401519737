@import "../../assets/styles/media";

.form-inner {
	.form-container {
		width: 100%;
		margin: 0 auto 0px;
	}

	.field-wrap {
		position: relative;
		margin: 10px 0;

		&.field-wrap-password {
			.field-help-text {
				position: static;
				margin: -16px 0 10px;
			}
		}

		.field-error,
		.field-help-text {
			position: absolute;
			top: 50px;
			font-size: 11px;
			line-height: 1;
			text-align: left;
		}

		.field-error {
			color: red;
		}

		::placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		::-webkit-input-placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		::-moz-placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		input:-moz-placeholder {
			color: var(--gray) !important;
			opacity: 1 !important;
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-box-shadow: 0 0 0 30px #fff inset !important;
			-webkit-text-fill-color: var(--blueDark) !important;
		}

		input:-internal-autofill-selected {
			background-color: white !important;
		}

		label {
			color: var(--gray) !important;
			font-size: 1.2rem;
		}
	}

	.field-wrap-help-password {
		font-size: 11px;
		line-height: 1.2;
	}

	.button {
		width: 100%;
		height: 38px;
	}
}

.container {
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 400px;
	flex: 1;
	justify-content: space-between;
	padding: 0 140px;
	justify-content: center;
	max-width: 700px;

	:global {
		.header {
			margin-top: 30px;
			margin-bottom: 20px;
			margin-left: auto;
			margin-right: auto;

			p {
				color: var(--gray);
				margin-top: 13px;
			}
		}
	}
}
