@import "../../../assets/styles/media";

.banner {
	width: 100%;
	box-sizing: border-box;
	background: rgba(255, 203, 0, 0.2);
	padding: 15px 15px 15px 44px;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	color: var(--text-color-primary);
	position: relative;

	&.warning {
		.icon {
			color: #ffcb00;
		}
	}

	&.danger {
		.icon {
			color: #ff0000;
		}
	}

	@include phablet-down {
		padding: 15px 15px 15px 34px;
	}

	.icon {
		position: absolute;
		top: 16px;
		left: 14px;
		font-size: 24px;

		@include phablet-down {
			left: 8px;
		}
	}

	.text-wrap {
		flex: 1 auto;
	}

	.headline {
		font-size: 20px;
	}

	.description {
		font-size: 13px;
		color: var(--text-color-secondary);
		margin-top: 7px;
	}

	.btn-wrap {
		min-width: fit-content;
		margin-top: 20px;

		@include mobile-down {
			margin-top: 10px;
		}
	}
}
