.welcome-slider {
	width: 100%;
	:global {
		.slick-slider {
			position: relative;

			img {
				transition: 0.5s 0.6s;
			}
		}

		.slick-list {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding-bottom: 50px !important;
		}

		.slick-slide {
			&:not(.slick-current) {
				img {
					opacity: 0.5;
				}
			}

			img {
				position: relative;
				z-index: 0;
				transform: scale(0.9);
			}
		}

		.slick-current {
			img {
				transform: scale(1.2);
				position: relative;
				transition: 0.5s 0.6s;
				z-index: 10;
			}
		}

		.slick-dots {
			position: absolute;
			bottom: -10px;
			left: 50%;
			transform: translateX(-50%);

			li {
				margin: 0;

				&:not(.slick-active) {
					button {
						&:focus {
							opacity: 0.25;
						}
					}
				}
			}

			button {
				&:before {
					font-size: 9px;
				}
			}
		}
	}
}
