.vicri-ranking {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	color: var(--text-color-primary);
}

.header-container {
	padding: 24px 24px 0 24px;
}

.content-container {
	max-width: 310px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	margin: 10px auto 10px;
	position: relative;
}

.description {
	font-size: 14px;
	text-align: center;
}

.footer-container {
	height: 47%;
	background: var(--background-color-primary);
	border-radius: 0 0 10px 10px;
	padding: 20px 24px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.active-conflict-area-icon {
	path {
		fill: var(--chart-conflict-icon-color);
	}
}
