.container {
	width: 100%;
}

.risk-name {
	font-size: 13px;
	font-weight: 700;
	color: var(--blueDark);
}

.line-box {
	margin-left: -3px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
}

.graph {
	max-width: 330px;
}

.score-info {
	display: flex;
	flex-shrink: 0;
	width: 60px;
	justify-content: space-between;
}

.score-number {
	font-size: 20px;
	font-weight: 700;
	width: 34px;
	text-align: left;
}

.score-tooltip {
	color: var(--grayLight);
	font-size: 18px;
	cursor: pointer;
}
