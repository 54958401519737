@import "../assets/styles/media";

.button-buy-report.button-buy-report-weight {
	@include mobile-down {
		align-self: center;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 300px;
	min-height: 44px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	color: var(--green);
	line-height: 16.1px;
	text-transform: uppercase;
	text-decoration: none;
	background-color: white;
	border: 3px solid var(--green);
	border-radius: 2px;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		color: white;
		background-color: var(--green);
		border: none;
	}
}

.try-our-free-tool {
	background-color: white;
	color: var(--colorTextGray);
	border: 3px solid var(--gray);

	&:hover {
		color: white;
		background-color: var(--gray);
		border: none;
	}
}

.price-old {
	display: inline-block;
	color: var(--grayLight);
	position: relative;

	&:after {
		content: "";
		background: var(--gray);
		width: calc(100% + 4px);
		height: 1.5px;
		position: absolute;
		top: 50%;
		left: -2px;
		right: -2px;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
