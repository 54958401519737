@import "../../assets/styles/media";

.profile-plans-wrap {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	flex-direction: column;
	color: var(--blueDark);

	.header {
		padding: 20px;
		font-size: 40px;
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px;
		gap: 10px;
		margin: 0 20px;
		background: #FFFFFF;
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		border-radius: 10px;
	}
}
