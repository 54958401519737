@import "../../../assets/styles/colors";
// @import "../../../assets/styles/media";

.additional-features-divider {
	text-align: center;
	position: relative;
	width: 100%;
	&-text {
		position: relative;
		display: inline-block;
		padding: 0 8px;
		color: $blueDark;
		background: #f8f9f9;
		z-index: 10;
	}
	&-line {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		height: 1px;
		background: $grayLight;
		width: 100%;
	}
}
