// TODO: need to actualize the colors

.link-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	font-size: 13px;
	line-height: 1.4;
	text-align: left;

	:global {
		.label-icon {
			margin-right: 3px;
			font-size: 18px;
		}
	}
}

.label-link {
	display: inline;
	opacity: 1;
	padding: 7px;
	color: var(--health);
	border-radius: 3px;

	&:hover {
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
		transition: 0.25s;
	}
}

.label-wrap {
	display: inline-flex;
	align-items: center;
	// ! TODO: need to actualize color!
	// color: var(--health);
	color: #997a00;
}

.label-content {
	display: inline-block;
	font-weight: 400;
	text-align: left;
}
