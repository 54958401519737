div.alert-interactive {
	width: 100%;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	padding: 20px;
	display: flex;
	justify-content: flex-start;
	gap: 8px;
	color: var(--text-color-primary);

	&.type-info {
		background: #e3d8f8;
	}

	&.type-warning {
		background: #ffcb0033;
	}

	&.type-errors {
		background: #ff000033;
	}

	&.type-success {
		background: #4caf50;
	}

	.icon-wrap {
		display: flex;
		align-items: center;
		color: #C10000;
		margin-left: -10px;
	}

	.icon {
		font-size: 34px;
	}

	.text-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 5px;
	}

	.title {
		font-size: 16px;
		color: inherit;
		font-weight: 400;
		line-height: normal;
	}

	.sub-title {
		font-size: 13px;
		color: var(--textGray);
		line-height: 1.4;
	}

	.text {
		font-size: 13px;
		color: inherit;
	}

	.button-wrap {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: auto;

		button {
			background: none;
			border: none;
			width: 24px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}