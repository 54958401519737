.risk-safety-summary-demo-container {
	* {
		padding: 0;
		margin: 0;
	}
}

main.risk-safety-summary-demo-container {
	.content {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin-left: auto;
		margin-right: auto;
		.title {
			padding-bottom: 12px;
		}
	}

	.section {
		display: flex;
		flex-direction: column;
		padding: 20px;
	}

	.section-widget-wrap {
		background: var(--background-color-secondary);
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		box-shadow: 0px 5px 10px 0px rgba(0, 24, 32, 0.07);
	}

	h3 {
		font-size: 26px;
		font-weight: 400;
		line-height: 1.44;
		padding: 0;
		margin: 0;
	}

	h4 {
		font-size: 20px;
		font-weight: 400;
		line-height: normal;
		margin-top: 0;
	}

	p {
		font-size: 16px;
		color: var(--text-color-primary);
	}

	.description {
		display: flex;
		flex-direction: column;
		grid-gap: 12px;
		gap: 12px;
		line-height: 1.44;
	}

	li {
		margin-bottom: 12px;
	}

	.text-placeholder {
		color: transparent;
		text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	.btn-wrap {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 16px;
	}

	.section-map {
		.button {
			display: flex;
			align-items: center;
			min-height: 40px;
			padding: 5px 20px;
			grid-gap: 8px;
			gap: 8px;
			font-size: 16px;
			background: #f8f9f9;
			border: none;
			border-radius: 6px;
			cursor: auto;

			&.active {
				background: #195688;
				.btn-text {
					color: #fff;
				}
			}
		}

		.btn-text {
			color: #195688;
		}

		.content-wrap {
			margin-top: 16px;
		}

		.map-placeholder {
			width: 100%;
		}
	}

	.info-action-fixed {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		background: #f3f3f3;
		border-radius: 0;
		display: flex;
		padding: 20px;
		line-height: 1.4;
		transform: scale(0);
		transition: 0.5s;
		box-shadow: 0 -5px 4px -4px rgba(0, 0, 0, 0.1);
		opacity: 0;
		visibility: hidden;

		&.info-action-fixed-done-enter {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}
	}
}
