@import "../../assets/styles/media";

$widgetGap: 30px;
$widgetHeight: 520px;

.dashboard-personal-block-sliders {
	width: 100%;
	display: flex;
	gap: $widgetGap;
	justify-content: center;

	@include mobile-down {
		flex-direction: column;
		align-items: center;
	}

	:global {
		.carousel-custom {
			padding-top: 10px;
		}
	}

	.widget-carousel-improve-your-se {
		height: $widgetHeight;
		display: flex;
		flex-direction: column;
		padding: 24px 20px 0;
		gap: 16px;
	}

	.widget-carousel-improve-your-se,
	.widget-carousel-more-from-se {
		@include between(768px, 1440px) {
			width: calc(100% / 2 - $widgetGap / 2);
		}
	}

	.widget-carousel-more-from-se {
		height: $widgetHeight;
	}
}
