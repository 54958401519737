.aireport-placeholder {
	.description {
		font-size: 24px;
		margin-top: 30px;
	}

	.images-wrap {
		display: flex;
		justify-content: space-between;
		gap: 15px;
		margin-top: 50px;
	}

	.slider {
		margin-top: 25px;

		:global {
			.slick-dots {
				bottom: -40px;

				li {
					&.slick-active button:before {
						color: var(--text-color-primary);
					}

					button:before {
						font-size: 9px;
					}
				}
			}
		}
	}

	.img-wrap {
		max-width: 380px;
		width: calc(100% - 46px);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 15px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 23px;
		padding-bottom: 23px;
	}

	.img {
		width: 100%;
		border-radius: 18px;
		box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.25);
	}
}
