@import "../../assets/styles/media";

.footer {
	min-height: 400px;
	font-size: 14px;

	.row-1 {
		display: flex;
		gap: 135px;

		@include mobile-down {
			flex-direction: column;
			gap: 30px;
		}
	}

	.col-1 {
		margin-top: 102px;

		@include mobile-down {
			margin-top: 35px;
		}
	}

	.col-2,
	.col-3 {
		margin-top: 111px;

		@include mobile-down {
			margin-top: 20px;
		}
	}

	.col-2 {
		margin-left: 51px;

		@include mobile-down {
			margin-left: 0;
		}
	}

	.logo {
		max-width: 181px;
	}

	.market-btns {
		margin-top: 30px;

		img {
			height: 43px;
		}
	}

	.title {
		font-size: 14px;
	}

	.links-wrap {
		display: flex;
		flex-direction: column;
		margin-top: 23px;
	}

	.link {
		text-decoration: underline;
		margin-top: 4px;
	}

	.copy {
		margin-top: 36px;

		@include mobile-down {
			padding-bottom: 50px;
		}
	}
}
