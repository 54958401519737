@import "../../assets/styles/media";

.section-header-wrap {
	font-size: 24px;
	color: var(--text-color-primary);
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.text-right {
	text-align: right;
}

.sub-title {
	margin-top: 30px;
	color: var(--text-color-secondary);

	@include mobile-down {
		font-size: 22px;
		color: var(--text-color-primary);
	}
}
