.container {
	width: 100%;
	max-width: 280px;
	height: 180px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
}

.detail-block {
	position: absolute;
	height: 50px;
	width: 100%;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.detail-number {
	font-size: 50px;
	font-weight: 700;
	position: absolute;
	bottom: 50px;
}

.detail-description {
	max-width: 170px;
	min-height: 22px;
	font-size: 14px;
	color: var(--blueDark);
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	position: absolute;
	bottom: 12px;
}

.detail-level {
	width: 310px;
	display: flex;
	justify-content: space-between;
	font-size: 11px;
	color: var(--gray);
	position: absolute;
	bottom: -3px;
	padding: 0 10px;
}
