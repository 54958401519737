@import "../../assets/styles/media";

.violent-crime-risk-index {
	min-height: 751px;
	background: url("./images/violentCrimeRiskIndexBg.png"), linear-gradient(279deg, #334b5d -1.94%, #6ca3ad 61%);
	background-repeat: no-repeat;
	background-size: cover;

	@include tablet-down {
		min-height: auto;
		padding-bottom: 50px;
	}

	@include mobile-down {
		padding-top: 177px;
		padding-bottom: 545px;
	}

	.header {
		color: var(--text-color-tertiary);

		:global {
			.h2 {
				line-height: 1.4;

				@include mobile-down {
					line-height: 1.1;
				}
			}
			.sub-title {
				max-width: 309px;
				color: var(--text-color-tertiary);
				margin-top: 44px;

				@include mobile-down {
					max-width: none;
					width: 100%;
					margin-top: 30px;
				}
			}
		}
	}

	.content {
		display: flex;
		position: relative;

		@include tablet-down {
			flex-direction: row-reverse;
			justify-content: space-between;
			gap: 36px;
		}

		@include mobile-down {
			flex-direction: column;
		}
	}

	.col-1 {
		max-width: 675px;
		padding-top: 172px;

		@include bigdesktop-down {
			max-width: 550px;
		}

		@include tablet-down {
			width: 50%;
			padding-top: 50px;
		}

		@include mobile-down {
			max-width: none;
			width: 100%;
		}
	}

	.col-2 {
		max-width: 738px;
		position: absolute;
		top: 308px;
		left: 466px;

		@include bigdesktop-down {
			right: 40px;
		}

		@include tablet-down {
			position: static;
			padding-top: 50px;
			max-width: 675px;
		}

		@include mobile-down {
			max-width: none;
			width: 100%;
			padding-top: 0;
			text-align: center;
		}
	}

	.img-mobile {
		max-width: 350px;
	}
}
