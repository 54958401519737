.stacked-cards {
	perspective: 1000px;
	position: relative;

	$maxCards: 4;

	.card {
		background: #fff;
		border-radius: 1rem;
		max-height: 80rem;
		box-shadow:
			0 0 2px 1px rgba(0, 0, 0, 0.1),
			0 2px 3px rgba(0, 0, 0, 0.16);
		width: 100%;

		transform-origin: 50% 0;
		transform: perspective(100px) translateY(75px) scale(0.9) rotateX(-10deg);
		opacity: 0;

		transition:
			all 0.4s ease,
			max-height 0.75s ease;

		&--added {
			transform: translateY(0px) scale(1);
			opacity: 1;
		}

		@for $i from 1 through $maxCards {
			&:nth-last-of-type(#{$i + 1}) {
				transform: translate3d(0, #{-$i * 10px}, #{-$i * 32px});
				opacity: #{1 - (($i - 1) * 0.15)};

				&:hover {
					transform: translate3d(0, calc(-100% - #{($i - 1) * 10px}), #{-$i * 32px});
				}
			}
		}

		&:nth-last-of-type(n + #{$maxCards}) {
			display: none;
		}

		&:nth-last-of-type(n + 2) {
			position: absolute;
			max-height: 80px;
			overflow: hidden;

			&:hover {
				opacity: 1;
				transition-delay: 0.333s;
			}
		}
	}
}
