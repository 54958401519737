.content {
	:global {
		.svg-block {
			width: 100%;
			height: 100%;
			background: #dee2e5;
		}

		.pie-chart {
			transform: rotate(-90deg);
			position: absolute;
			top: 0;
			width: 221px;
			height: 221px;
			border-radius: 50%;
			--value: 30%;
			background: conic-gradient(green var(--value), transparent 0);
		}

		.graph-circle {
			position: relative;

			.graph-value-scale {
				transform-origin: center;
			}

			.value-scale {
				opacity: 0;

				&.active {
					opacity: 1;
				}
			}

			text {
				font-weight: 700;
			}
		}

		.block-text-index {
			font-size: 13px;
			color: var(--gray);
			position: absolute;
			top: 115px;
		}
	}
}

.block-text-score {
	width: 100%;
	font-size: 50px;
	font-weight: 700;
	color: var(--text-color-primary);
	text-align: center;
	position: absolute;
	bottom: 50px;
}

.location-extra-wrap {
	width: 100%;
	min-height: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	bottom: -5px;
	text-align: center;
	padding-top: 5px;
}

.block-text-location {
	width: 60%;
	min-height: 22px;
	font-size: 14px;
	color: var(--text-color-primary);
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.block-extra-info {
	width: 60%;
	font-size: 12px;
	font-weight: 700;
}

.block-text-state {
	width: 100%;
	display: flex;
	justify-content: space-between;
	font-size: 11px;
	color: var(--text-color-secondary);
	position: absolute;
	bottom: -5px;
	padding: 0 10px;
}
