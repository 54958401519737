:root {
	--white: #fff;
	--dark: #001a21;
	--blueDark: #233544;
	--blueMediumDark: #334b5d;
	--gray: #7d8c97;
	--textGray: #5b6a76;
	--grayLight: #dee2e5;
	--violet: #9356f8;
	--cyanLight: #7ef2f3;
	--health: #ffcb00;
	--crime: #ff0000;
	--green: #6ad400;
	--errors: #ec7070;
	--newLink: #195688;
	--vicriWar: #390505;

	--colorRiskDefault: #dee2e5;
	--colorRisk1: #6ad400;
	--colorRisk2: #b5cf01;
	--colorRisk3: #ffcb00;
	--colorRisk4: #ff8e01;
	--colorRisk5: #ff3c01;

	--border-color-primary: #195688;
	--border-color-primary-hover: #127fbc;
	--border-color-primary-disabled: #7d8c97;
	--border-color-secondary: #dee2e5;

	/*Heatmap colors (blue - violet)*/
	.blue-heatmap-01 {
		background: #1ff1f2;
	} /* 0 - 0.01*/

	.blue-heatmap-02 {
		background: #2eddf3;
	} /* 0.01 - 1*/

	.blue-heatmap-03 {
		background: #40c5f4;
	} /* 1 - 10*/

	.blue-heatmap-04 {
		background: #4bb6f5;
	} /* 10 - 50*/

	.blue-heatmap-05 {
		background: #54aaf5;
	} /* 50 - 100*/

	.blue-heatmap-06 {
		background: #6298f6;
	} /* 100 - 200*/

	.blue-heatmap-07 {
		background: #6e87f6;
	} /* 200 - 400*/

	.blue-heatmap-08 {
		background: #7c75f7;
	} /* 400 - 600*/

	.blue-heatmap-09 {
		background: #826df7;
	} /* 600 - 800*/

	.blue-heatmap-10 {
		background: #9356f8;
	} /* 800 - 1000*/

	--background-color-primary: #f8f9f9;
	--background-color-secondary: #fff;
	--background-color-tertiary: #334b5d;
	--text-color-primary: #233544;
	--text-color-secondary: #7d8c97;
	--text-color-tertiary: #fff;
	--text-link-color-primary: #195688;
	--text-link-color-primary-hover: #127fbc;
	--text-link-color-primary-disabled: #7d8c97;

	--link-background-primary: #f8f9f9;
	--link-background-primary-hover: #fff;

	--border-color-primary: #195688;
	--border-color-primary-hover: #127fbc;
	--border-color-primary-disabled: #7d8c97;

	--chart-conflict-icon-color: #390505;
	--alarm-normal-color: #6ad400;

	--button-background-color-primary: #195688;
	--button-text-color-primary: #fff;
	--button-background-color-primary-disabled: #7d8c97;
	--button-text-color-primary-disabled: #dee2e5;
	--button-background-color-primary-hover: #127fbc;
	--button-text-color-primary-hover: #6cfeff;

	--button-background-color-secondary: #7ef2f3;
	--button-text-color-secondary: #195688;
	--button-background-color-secondary-disabled: #dee2e5;
	--button-text-color-secondary-disabled: #7d8c97;
	--button-background-color-secondary-hover: #6cfeff;
	--button-text-color-secondary-hover: #127fbc;

	--button-tab-background-primary: #f8f9f9;
	--button-tab-background-primary-active: #195688;
	--button-tab-text-color-primary-active: #fff;

	--icon-background-color-one: #127fbc;
	--icon-background-color-two: #6ad400;
	--icon-background-color-three: #ff0000;
	--icon-color-one: #127fbc;
	--icon-color-two: #6ad400;
	--icon-color-three: #ff0000;
	--icon-color-four: #fff;
}

.theme-dark {
	--background-color-primary: #334b5d;
	--background-color-secondary: #233544;
	--text-color-primary: #fff;
	--text-color-secondary: #f8f9f9;
	--text-link-color-primary: #fff;
	--chart-conflict-icon-color: #dee2e5;
}
