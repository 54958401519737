.slider-line {
	width: 100%;
	height: 5px;
	border-radius: 5px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

:global {
	.slider-line-first {
		background: var(--crime);
	}

	.slider-line-last {
		background: var(--health);
	}
}
