div.failed-to-generate-section {
	padding: 20px;
	display: flex;
	justify-content: center;

	.wrapper {
		.title-block {
			display: flex;
			flex-direction: row;
			gap: 5px;

			.icon-wrap {
				.icon {
					color: #C10000;
					font-size: 34px;
				}
			}

			.title {
				font-size: 26px;
				color: var(--blueDark);
			}
		}

		.subtitle {
			font-size: 16px;
			color: var(--textGray);
		}
	}
}