.upload-image-wrap {
	button {
		border: none;
		background: transparent;
	}

	.avatar-box {
		display: flex;
		align-items: center;
		margin-top: 10px;
	}

	.errors-wrap {
		margin-left: 7px;
		font-size: 11px;
		color: var(--errors);
		line-height: 1.4;
		text-align: left;
	}
}
