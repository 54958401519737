.footer-info-title {
	color: var(--text-color-primary);
	font-size: 16px;
	font-weight: 700;
	padding-bottom: 15px;
}

.footer-info-text {
	font-size: 13px;
	color: var(--text-color-secondary);
	line-height: 1.4;
}
