@import "../../assets/styles/colors";

.page-conflict-map {
	.body {
		overflow-x: hidden;
	}

	.iframe-map-container {
		width: calc(100% + 6.4rem);
		height: 100vh;
		margin-left: -6.4rem;
	}

	@media only screen and (max-width: 1023px) {
		margin-top: 8rem;
		.iframe-map-container {
			width: 100%;
			margin-left: 0;
		}
	}
}
