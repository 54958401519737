// @import "../../../assets/media";
@import "../../../assets/styles/media";
@import "../../../assets/styles/colors";

.additional-features-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 30px;

	@include tablet-down {
		flex-direction: column;
		align-items: center;
	}

	@include mobile-down {
		gap: 12px;
	}

	.block-additional-features {
		// max-width: 494px;
		width: 100%;

		@include mobile-down {
			width: 100%;
		}

		.pro-features-list {
			& .pro-features-item {
				margin-top: 5px;
				padding: 1px 0;
				display: flex;
				align-items: flex-start;

				& .check-icon {
					color: $green;
					font-size: 20px;
					padding-right: 5px;
					font-weight: 700;
				}
			}
		}
	}
}
