@import "../../assets/styles/_media.scss";

$scaleWidth: 16px;
$scaleFullHeight: 228px;
$sliderSize: 36px;
$transitionDuration: 0.3s;
$transitionDelay: 0.05s;

.guess-risk {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	flex: auto;

	@include phablet-up {
		padding: 36px 25px 0;
	}

	.sub-title {
		margin-top: 25px;
	}

	.risk-choose-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		min-height: 206px;
		margin: 30px 0;
	}

	.guess-risk-step1 {
		@include narrower-than(1400px) {
			.risk-choose-wrap {
				justify-content: space-around;
				margin-left: 30px;
				margin-right: 30px;
			}
			.picker-wrap {
				margin-right: -34px;
			}
		}

		.risk-scale-wrap {
			@include wider-than(1400px) {
				position: absolute;
				top: 0;
				left: 90px;
			}
		}
	}

	.guess-risk-step2 {
		.btn-wrap {
			button {
				max-width: none;
				width: auto;
				padding: 0 17.5px;

				.material-icons {
					font-variation-settings: "FILL" 0, "wght" 500, "GRAD" -25, "opsz" 20;
				}
			}
		}
	}

	.wrap-guess-crime-risk-picker {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.border-color-red {
			border-color: var(--crime) !important;
		}

		.border-color-green {
			border-color: var(--green) !important;
		}

		.border-color-gray {
			border-color: var(--gray) !important;
		}

		.box-mark-description {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-bottom: 10px;

			.mark-description-variant {
				display: inline-block;
				border: 1px solid var(--gray);
				border-radius: 8px;
				padding: 4px 8px;
			}
		}

		.box-slider {
			height: $scaleFullHeight;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.block-scale {
			width: $scaleWidth;
			height: $scaleFullHeight - $sliderSize;
			position: relative;
		}

		.wrap-marks {
			height: 100%;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				background: var(--white);
				z-index: 10;
				opacity: 0;
				transition: opacity $transitionDuration;
			}

			&.marks-active {
				&::before {
					opacity: 0.2;
					transition: opacity $transitionDuration;
				}
			}
		}

		.marks {
			width: 100%;
			background: var(--gray);
			position: absolute;
			border-bottom: 1px solid var(--white);

			&:first-of-type {
				border-radius: 8px 8px 0 0;
			}

			&:last-of-type {
				border-bottom: none;
				border-radius: 0 0 8px 8px;
			}

			.label {
				position: absolute;
				width: 30px;
				height: fit-content;
				right: calc(100% + 25px);
				bottom: -7px;
				font-size: 10px;
				color: var(--gray);
				line-height: 1.1;
				text-align: right;
				transition: $transitionDuration;
			}

			&-plus {
				.label {
					top: -7px;
				}
			}

			&[data-value="100"] {
				border-bottom: none;
			}

			&[data-value="0"] {
				width: 32px;
				height: 2px;
				background: var(--gray);
				left: -8px;

				.label {
					top: -5px;
					right: 48px;
				}
			}

			&.mark-active {
				.label {
					transform: scale(2, 2);
					right: calc(100% + 30px);
				}
			}

			&.color-green {
				background: var(--green);
			}

			&.color-red {
				background: var(--crime);
			}
		}

		.destination {
			background: var(--white);
			display: flex;
			justify-content: center;
			align-items: center;
			width: $sliderSize;
			height: $sliderSize;
			border: 3px solid var(--gray);
			border-radius: 50%;
			position: absolute;
			right: -10px;
			z-index: 20;
		}

		.home {
			position: absolute;
			display: flex;
			align-items: center;
			left: calc(100% + 8px);
		}
	}

	.btn-wrap {
		margin-top: 28px;
		display: flex;
		justify-content: center;
		flex-wrap: wrap-reverse;

		button {
			max-width: 242px;
			min-width: 242px;
			width: 100%;
			margin: 20px 10px 0;
		}
	}
}
