@import "../../../assets/styles/media";

.container {
	z-index: 999;
	position: absolute;
	right: 0;
	top: 70px;
	cursor: auto;
	color: var(--blueDark);
	background-color: var(--background-color-primary);
	padding: 20px;
	min-width: 350px;
	width: auto;
	border-radius: 8px;

	display: flex;
	flex-direction: column;
	gap: 10px;
	justify-content: center;
	align-items: center;

	@include mobile-down {
		position: fixed;
		top: 70px;
		left: 0;
		right: 0;
		width: 100vw;
		min-width: auto;
		// max-width: 400px;
	}

	.avatar {
		width: 72px;
		height: 72px;
		border-radius: 10px;
		border: 2px solid white;
		background-size: auto 100%;
		background-position: center;
		box-shadow: 0px 1px 4px 0px rgba(59, 61, 107, 0.25);
	}

	.name {
		font-size: 20px;
		font-weight: 400;
		margin-bottom: 10px;
	}

	.details {
		align-self: flex-start;

		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.plan {
		width: 100%;
		background-color: white;
		border-radius: 4px;
		// box-shadow: 0px 5px 10px 0px rgba(0, 24, 32, 0.07);
	}

	.actions {
		width: 100%;
	}

	.divider {
		width: 100%;
		border-top: 1px solid var(--grayLight);
		margin: 10px 0;
	}
}

.row {
	display: flex;
	font-size: 14px;

	.label {
		min-width: 80px;
		margin-right: 18px;
		font-weight: 400;
	}

	.value {
		word-break: break-all;
		font-weight: 700;
	}
}