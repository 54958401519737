@import "../../assets/styles/media";

$widgetGap: 30px;
$widgetHeight: 520px;
$widgetWidth: 440px;

.page-dashboard-personal {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: $widgetGap;
	margin-top: 30px;
	color: var(--text-color-primary);
	background-image: url("./images/planeTrajectory.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: 0 -15%;
	@include mobile-down {
		background-image: none;
	}

	h3 {
		font-size: 26px;
		font-weight: 400;
		line-height: 37px;
	}

	h4 {
		font-size: 20px;
		font-weight: 400;
		line-height: 23px;
	}
}

.dashboard-first-page {
	width: 100%;
	display: flex;
	justify-content: center;
	gap: $widgetGap;

	@include mobile-down {
		flex-direction: column;
	}
}

.dashboard-personal-column {
	@include between(768px, 1400px) {
		max-width: calc(50% - $widgetGap / 2);
	}
}

.widget {
	width: 100%;
	gap: $widgetGap;
	max-width: $widgetWidth;
	min-width: 0;
	max-height: $widgetHeight;

	&.widget-full-height {
		max-height: none;
	}
}

.widget-vicri-ranking {
	height: $widgetHeight;
}

.widget-personalized-risk-score {
	height: $widgetHeight;

	@include mobile-down {
		height: auto;
		max-height: none;
	}
}

.dashboard-personal-column-left {
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
	gap: $widgetGap;

	max-width: $widgetWidth;

	@include tablet-up {
		// max-height: calc($widgetHeight * 2 + $widgetGap); //1070px
	}

	@include narrower-than(1399px) {
		align-items: flex-end;
		max-width: none;
	}

	@include mobile-down {
		align-items: center;
	}
}

.dashboard-personal-column-right {
	display: flex;
	flex-direction: column;
	flex: 2 1 0;
	gap: $widgetGap;

	@include narrower-than(1399px) {
		flex: 1 1 0;
	}

	@include mobile-down {
		align-items: center;
	}
}
.dashboard-personal-block-rankings {
	display: flex;
	width: 100%;
	gap: $widgetGap;

	@include narrower-than(1399px) {
		flex-direction: column;
	}

	@include mobile-down {
		align-items: center;
	}
}
