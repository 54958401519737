.risk-calculation {
	width: 100%;
	height: 56px;
	margin-bottom: 5px;
	z-index: 0;
	position: relative;

	.select-block {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		margin: 0;
		position: relative;
		border-radius: 2px;
		background-color: white;
		box-shadow: 0px 4px 8px 0px rgba(0, 24, 32, 0.12);
	}

	.icon-place {
		position: absolute;
		left: 3px;
	}

	.icon-clear {
		position: absolute;
		right: 60px;
		z-index: 2;
		cursor: pointer;
	}

	.button {
		position: absolute;
		width: 46px;
		height: 46px;
		top: 5px;
		right: 5px;
	}
}
