@import "../../assets/styles/media";

.page-report-expired {
	.content {
		justify-content: space-between;
	}

	.block-text {
		text-align: center;
	}

	.title {
		font-size: 26px;
		color: var(--text-color-primary);
		line-height: 1.3;

		@include tablet-up {
			font-size: 34px;
		}
	}

	.description {
		font-size: 18px;
		color: var(--text-color-secondary);
		line-height: 1.33;
		margin-top: 20px;

		@include tablet-up {
			font-size: 20px;
			line-height: 1.15;
		}
	}

	.link-return {
		color: var(--violet);
		position: relative;

		&:after {
			content: "";
			background: var(--violet);
			height: 2px;
			border-radius: 2px;
			position: absolute;
			left: 50%;
			right: 50%;
			bottom: 0;
			opacity: 0;
			transition: 0.25s;
		}

		&:hover:after {
			left: 0;
			right: 0;
			opacity: 1;
		}
	}

	.footer {
		@include mobile-down {
			margin-top: 56px;
		}

		:global {
			.footer-content {
				@include mobile-down {
					padding: 0;
					margin-bottom: 0;
				}
			}
		}
	}
}
