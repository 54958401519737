@import "../../assets/styles/colors";

.user-profile-wrap {
	max-width: 323px;
	width: 100%;
	margin-top: 35px;

	.user-title {
		margin-top: 17px;
		color: $blueDark;
	}

	.field-error {
		min-height: 22px;
		font-size: 14px;
		color: $crime;
		text-align: center;
	}

	.user-text {
		font-size: 13px;
		color: $gray;
		line-height: 1.4;
		margin-top: 10px;
	}

	.field-container {
		margin-bottom: 15px;
	}

	.user-body {
		margin-top: 10px;

		.field-container-name {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			position: relative;

			.first-name {
				max-width: 37%;
				flex: 1;
			}

			.last-name {
				max-width: 57%;
				flex: 1;
			}

			.field-error-text {
				flex: 0 0 100%;
				position: absolute;
				top: calc(100% + 1px);
				font-size: 11px;
				color: var(--errors);
				line-height: 1;
			}
		}
	}

	.input-wrap {
		input[type="password"]::placeholder {
			color: $grayLight !important;
			opacity: 1 !important;
		}

		input[type="password"]::-webkit-input-placeholder {
			color: $grayLight !important;
			opacity: 1 !important;
		}

		input[type="password"]::-moz-placeholder {
			color: $grayLight !important;
			opacity: 1 !important;
		}

		input[type="password"]:-moz-placeholder {
			color: $grayLight !important;
			opacity: 1 !important;
		}

		input {
			color: $blueDark !important;
		}
	}

	.help-text {
		font-size: 11px;
	}

	.submit-edit {
		max-width: 182px;
		display: flex;
		justify-content: center;
		margin: 75px auto 35px;
	}
}
