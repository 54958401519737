@import "../assets/styles//media";

.similar-cities {
	width: 293px;
	height: 96px;
	box-shadow: 0 5px 10px rgba(0, 24, 32, 0.07);
	border-radius: 10px;
	padding: 8px 20px;
	color: var(--text-color-tertiary);

	@include tablet-down {
		width: 100%;
	}

	.content-wrap {
		display: flex;
		justify-content: space-between;
	}

	.left-block {
		width: 60%;
		margin-top: 5px;
	}

	.location {
		font-size: 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.location-title {
		font-size: 12px;
	}

	.percentile-rank {
		margin-top: 7px;
	}

	.risk-rating-data,
	.percentile-rank-data {
		font-size: 23px;

		span {
			font-size: 13px;
			vertical-align: baseline;
		}
	}

	.risk-rating-title {
		font-size: 11px;
		line-height: 10px;
		margin-top: 0.2re;
	}

	.right-block {
		text-align: right;
	}
}
