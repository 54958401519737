@import "../assets/styles/media";

.page-404 {
	height: 100%;

	.body {
		@include tablet-up {
			flex: auto;
			margin-top: 40px;
		}

		position: relative;
		z-index: 10;

		.block-text {
			margin-top: -19%;
			position: relative;
			z-index: 10;

			@include tablet-up {
				max-width: 300px;
				margin-top: 0;
			}
		}

		h1 {
			font-size: 34px;
			color: var(--text-color-primary);
		}

		p {
			font-size: 20px;
			color: var(--text-color-secondary);
			margin-top: 20px;
		}

		.btn-back {
			margin-top: 20px;

			button {
				height: 32px;
				background-color: var(--background-color-secondary);
				font-size: 12px;
				line-height: 1;
				padding: 9px 12px;
				letter-spacing: -0.1px;

				&:hover {
					background-color: #f8f9f9;
				}

				.arrow-icon {
					font-size: 12px;
				}
			}

			.material-symbols-outlined {
				font-size: 12px;
				color: var(--text-color-primary);
				font-variation-settings: "wght" 700;
			}
		}

		.bg-wrap {
			margin-top: 20px;

			@include tablet-up {
				margin-top: 0;
			}

			@include mobile-down {
				position: relative;
			}
		}

		.bg-img {
			max-width: 900px;
			width: 100%;
			z-index: -1;

			@include tablet-up {
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				-moz-transform: translate(-50%, -50%);
				-ms-transform: translate(-50%, -50%);
				-o-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
			}
		}

		.img-404 {
			max-width: 265px;
			position: absolute;
			top: calc(50% - 19%);
			left: 50%;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			-ms-transform: translate(-50%, -50%);
			-o-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			z-index: 10;

			@include tablet-up {
				max-width: 498px;
			}
		}
	}

	.footer {
		margin-top: auto;

		:global {
			.footer-content {
				@include mobile-down {
					padding: 0;
				}
			}
		}
	}
}
