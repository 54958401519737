@import "../../assets/styles/colors";

.notification-story-item-container {
	max-width: 31rem;
	width: 100%;
	border-radius: 1rem;
	padding: 1.2rem 1rem 2rem;
	margin-top: 1rem;
	position: relative;
	transition: box-shadow 0.25s;

	&:after {
		content: "";
		width: 1.5rem;
		height: 1.5rem;
		background: $violet;
		border-radius: 50%;
		position: absolute;
		top: -0.5rem;
		right: -0.5rem;
	}

	&.notification-read {
		&:after {
			opacity: 0;
			transition: 0.3s 0.5s;
		}
	}

	.item-pre-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.item-notification-type-icon {
		display: flex;
		width: 20px;
		height: 20px;
	}

	.item-close-btn {
		display: flex;
		font-size: 2.5rem;
		color: $blueDark;
		cursor: pointer;
		transition: opacity 0.25s;
		opacity: 0.63;
		transition: 0.3s;
	}

	.header {
		color: $blueDark !important;
		margin-top: 0.2rem;
	}

	.body {
		font-size: 1.3rem;
		color: $gray;
		line-height: 1.4;
		margin-top: 0.2rem;
	}

	.footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1.5rem;

		.body-url {
			margin-top: 0.5rem;

			a {
				width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: #97aebe;
				padding-right: 2rem;
				transition: 0.3s;

				&:hover {
					color: $violet;
					text-decoration: underline;
					transition: 0.3s;
				}
			}
		}

		.btn {
			min-width: 8rem;
			height: 3rem;
			display: flex;
			align-items: center;
			border: none;
			border-radius: 5rem;
			padding: 0.8rem 1rem 0.6rem 0.7rem;
			font-size: 1.2rem;
			color: #9356f8;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			cursor: pointer;
			transition: 0.25s;

			&:hover {
				box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
			}
		}

		.notification-date {
			font-size: 1.1rem;
			text-align: right;
		}
	}

	@media only screen and (min-width: 768px) {
		.item-close-btn {
			visibility: hidden;
			opacity: 0;
		}

		&:hover {
			box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);

			.item-close-btn {
				visibility: visible;
				opacity: 0.63;
				transition: 0.3s;

				&:hover {
					transform: scale(1.2);
					transition: 0.3s;
				}
			}
		}
	}
}
