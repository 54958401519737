.personalized-risk-score {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.personalized-risk-score-top {
		padding: 24px 24px 0 24px;
	}

	.personalized-risk-score-bottom {
		height: 47%;
		background: #f8f9f9;
		border-radius: 0 0 10px 10px;
		padding: 20px 24px 24px;
	}

	.risk-score-title {
		color: var(--blueDark);
		font-size: 16px;
		font-weight: 700;
		padding-bottom: 10px;
	}
}
