@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

$colorTextGray: #5b6a76;

.additional-features-wrapper {
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
	box-shadow: 0px 5px 10px rgb(0 24 32 / 7%);
	background: white;
	border-radius: 10px;
	padding: 20px;

	&.try-our-free-tool {
		background-color: #f8f9f9;
	}

	@include mobile-down {
		flex-direction: column-reverse;
		align-items: center;
		gap: 16px;
		padding: 16px;
	}

	.additional-features-block {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		&.additional-features-right {
			max-width: 180px;
			width: 100%;
			justify-content: center;

			.img-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 180px;
				height: 180px;

				&.unlock-pro-version-report {
					background: radial-gradient(50% 50% at 50% 50%, rgba(106, 212, 0, 0.3) 0%, rgba(106, 212, 0, 0) 100%);
				}

				&.try-our-free-tool {
					background: radial-gradient(50% 50% at 50% 50%, rgba(126, 242, 243, 0.4) 0%, rgba(126, 242, 243, 0) 100%);
				}
			}
		}

		.additional-features-title {
			color: $blueMediumDark;
			font-weight: 700;
			line-height: 23px;
			margin: 0;
			align-self: flex-start;
		}

		.additional-features-description {
			color: $blueMediumDark;
			font-size: 16px;
			font-weight: 400;
			line-height: 22.4px;
			align-self: flex-start;
			margin-top: 14px;
		}

		.button-wrapper {
			margin-top: 25px;
			width: 100%;
		}

		.additional-features-button {
			@include mobile-down {
				align-self: center;
			}

			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 300px;
			min-height: 44px;
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 16.1px;
			background-color: white;
			color: $green;
			border: 3px solid $green;
			border-radius: 2px;
			cursor: pointer;
			transition: 0.3s;
			text-transform: uppercase;
			margin-top: 25px;

			&:hover {
				color: white;
				background-color: $green;
				border: none;
			}

			&.try-our-free-tool {
				background-color: white;
				// color: $gray;
				color: $colorTextGray;
				border: 3px solid $gray;

				&:hover {
					color: white;
					background-color: $gray;
					border: none;
				}
			}

			.price-old {
				display: inline-block;
				color: $grayLight;
				position: relative;

				&:after {
					content: "";
					background: $gray;
					width: calc(100% + 4px);
					height: 1.5px;
					position: absolute;
					top: 50%;
					left: -2px;
					right: -2px;
					-webkit-transform: translateY(-50%);
					-moz-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					-o-transform: translateY(-50%);
					transform: translateY(-50%);
				}
			}
		}
	}
}
