@import "../../assets/styles/media";


.layout-profile {
	.content {
		padding: 0;
		display: flex;
		max-width: none;
	}

	.header {
		height: 72px;
		align-items: center;
		display: flex;
		position: relative;
		background: var(--white);
		border-bottom: 1px solid var(--grayLight);
		margin: 0;
		max-width: none;

		:global {
			.btn-alerts-wrap {
				.notifications-alerts {
					width: 40px;
					height: 40px;
					border-radius: 10px;
					width: 40px;
					height: 40px;
					background: #FFFFFF;
					border: 2px solid #FFFFFF;
					box-shadow: 0px 1px 4px rgba(59, 61, 107, 0.25);
					border-radius: 10px;

					.bell-animate {
						width: 24px;
						height: 24px;
						left: 6px;
					}
				}
			}
		}
	}

	.main {
		display: flex;
		justify-content: space-around;
		flex: 1;
	}

	.box-left {
		display: flex;
		align-items: flex-start;
		flex: 1;
		max-width: 308px;
		flex-direction: column;
		justify-content: space-between;
		background-color: white;

		.logo {
			height: 80px;
			margin: 0 auto;
			display: flex;
			align-items: center;

			img {
				max-height: 80px;
			}
		}

		.side-menu {
			display: flex;
			flex-direction: column;
			padding: 0 16px;
			padding-top: 24px;
			width: 240px;

			.side-menu-item {
				height: 46px;
				display: flex;
				align-items: center;

				.side-menu-item-icon {
					padding: 0 8px;
				}

				&.side-menu-item-selected {
					font-weight: bold;
				}
			}
		}
	}

	.box-right {
		display: flex;
		align-items: center;
		flex: 1;
		flex-direction: column;
	}

}
