$white: #fff;
$dark: #001a21;

$blueDark: #233544;
$blueMediumDark: #334b5d;
$gray: #7d8c97;
$grayLight: #dee2e5;
$violet: #9356f8;
$cyanLight: #7ef2f3;
$health: #ffcb00;
$crime: #ff0000;
$green: #6ad400;
$errors: #ec7070;
$newLink: #195688;

.bg-transparent {
	background-color: transparent;
}

.bg-violet {
	background: rgba(147, 86, 248, 0.2);
}

.bg-green {
	background: rgba(106, 212, 0, 0.2);
}

.bg-red {
	background: rgba(255, 0, 0, 0.2);
}

.bg-yellow {
	background: rgba(255, 203, 0, 0.2);
}

.bg-gray-light {
	background: $grayLight;
}

.color-gray {
	color: $gray;
}

/*Heatmap colors*/
.heatmap-00 {
	background: #dee2e5;
}

.heatmap-01 {
	background: #6ad400;
} /* 0 - 0.01*/

.heatmap-02 {
	background: #97d101;
} /* 0.01 - 1*/

.heatmap-03 {
	background: #b5cf01;
} /* 1 - 10*/

.heatmap-04 {
	background: #d3ce01;
} /* 10 - 50*/

.heatmap-05 {
	background: #ffcb00;
} /* 50 - 100*/

.heatmap-06 {
	background: #ffb601;
} /* 100 - 200*/

.heatmap-07 {
	background: #ff8e01;
} /* 200 - 400*/

.heatmap-08 {
	background: #ff6401;
} /* 400 - 600*/

.heatmap-09 {
	background: #ff3c01;
} /* 600 - 800*/

.heatmap-10 {
	background: #ff0000;
} /* 800 - 1000*/

.heatmap-11 {
	background: #c10000;
}

.heatmap-12 {
	background: #390505;
}

/*Heatmap colors (blue - violet)*/
.blue-heatmap-01 {
	background: #1ff1f2;
} /* 0 - 0.01*/

.blue-heatmap-02 {
	background: #2eddf3;
} /* 0.01 - 1*/

.blue-heatmap-03 {
	background: #40c5f4;
} /* 1 - 10*/

.blue-heatmap-04 {
	background: #4bb6f5;
} /* 10 - 50*/

.blue-heatmap-05 {
	background: #54aaf5;
} /* 50 - 100*/

.blue-heatmap-06 {
	background: #6298f6;
} /* 100 - 200*/

.blue-heatmap-07 {
	background: #6e87f6;
} /* 200 - 400*/

.blue-heatmap-08 {
	background: #7c75f7;
} /* 400 - 600*/

.blue-heatmap-09 {
	background: #826df7;
} /* 600 - 800*/

.blue-heatmap-10 {
	background: #9356f8;
} /* 800 - 1000*/
