@import "../assets/styles/media";

.InfoLGBTQI {
	display: flex;
	align-items: center;

	:global {
		#tooltip-LGBTQI {
			.MuiTooltip-tooltip {
				padding: 15px;
				text-align: left;

				@include tablet-up {
					width: 400px;
				}
			}

			.tooltip-text {
				.tooltip-description-bold,
				.tooltip-description {
					color: #fff;
				}
			}

			.tooltip-link {
				color: var(--cyanLight);
			}
		}
	}
	.block-info-icon {
		display: flex;
		cursor: pointer;

		.icon-info.small-icon {
			font-size: 14px;
		}
	}
}
