@import "../../../assets/styles/colors";
@import "../../../assets/styles/media";

.risk-scores-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 317px;
	width: 100%;
	padding-top: 20px;
	background: var(--background-color-secondary);
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);
	position: relative;

	@include mobile-down {
		max-width: 495px;
	}

	.drivers {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		border-top: 1px solid var(--grayLight);
	}

	.risk-drivers-title {
		font-size: 14px;
		color: var(--text-color-secondary);
		font-weight: normal;
		margin: 0 0 20px;
	}

	.risk-drivers-data:not(:last-of-type) {
		margin-bottom: 20px;
	}

	.risk-drivers-data-narrow:not(:last-of-type) {
		margin-bottom: 20px;
	}

	.risks-data-top {
		width: 100%;
		text-align: center;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.title {
		font-size: 20px;
		color: var(--text-color-primary);
		font-weight: 400;
		line-height: 1.15;
		text-align: left;
		margin: 0;
		padding: 0;
	}

	.row-1 {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;

		.col-1 {
			max-width: 85px;
			height: auto;
			padding-top: 16px;
		}
	}

	.communicable-drivers {
		display: flex;
		justify-content: space-between;

		> .col {
			width: 47%;
		}
	}

	.increase-decrease {
		width: 100%;
		height: 180px;
	}

	.risk-score-lines {
		width: 192px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding-top: 13px;
	}

	.risk-score-line-wrap {
		display: flex;
		margin-bottom: 14px;
	}

	.score-line-contributors {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		padding-top: 13px;
	}
}
