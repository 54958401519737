@import "../../assets/styles/media";

.report-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s;

	// @include tablet-down {
	// 	margin-top: 40px;
	// }

	a {
		color: #2076d8;
		text-decoration: underline;
	}
}

.report-container-done-enter {
	visibility: visible;
	opacity: 1;
}

.traveller-info-container {
	max-width: 1060px;
	width: 100%;
	margin: 30px auto 0;
}
