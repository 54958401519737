@import "../../assets/styles/media";

.page-get-started {
	.content {
		padding: 0;
		display: flex;
		max-width: none;
	}

	.header-top {
		height: 72px;
		align-items: center;
    display: flex;

		background: var(--white);
		border-bottom: 1px solid var(--grayLight);;

		@include mobile-down {
			background: none;
			border-bottom: 0;
		}

		.logo {
			img {
				padding-left: 40px;
			}
		}
	}

	.main {
		display: flex;
		justify-content: space-around;
		flex: 1;
	}

	.box-left {
		display: flex;
		align-items: center;
		flex: 1;
		max-width: 676px;
		flex-direction: column;
	}

	.box-right {
		display: flex;
		align-items: center;

		background: linear-gradient(
                     rgba(0,0,0, .5),
                     rgba(0,0,0, .5)),
                     url("./../../../images/get_started_background.jpeg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 68% 0;
		flex: 1;
		justify-content: space-around;
		text-align: center;
		color: var(--white);

		> div {
			padding: 0 60px;
		}

		h1 {
			line-height: 1.3;
		}

		p {
			font-size: 24px;
			line-height: 1.5;
		}

		.app-download-container {
			display: flex;
			margin-top: 32px;
			justify-content: center;
			gap: 16px;
		}
	}

	.btn-store {
		&:hover {
			opacity: 0.7;
		}
	}

	.link {
		margin-top: 32px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		color: var(--cyanLight);
		font-weight: 700;
		letter-spacing: 0.7px;
	}
}
