@import "../../../assets/styles/media";

.slider-slick-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;

	:global {
		.slick-slider {
			height: 100%;
		}

		.slick-dots {
			bottom: 0;
			li {
				width: 6px;

				button:before {
					color: var(--text-color-secondary);
					opacity: 1;
				}

				&.slick-active button:before {
					color: var(--text-link-color-primary);
				}
			}
		}
	}
}

.btn-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	position: absolute;
	gap: 25px;
	bottom: 30px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 10;
}

.btn-nav {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	gap: 5px;
	border: none;
	font-size: 14px;
	color: var(--text-link-color-primary);
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;

	.btn-nav-text {
		@include tablet-down {
			display: none;
		}
	}

	:global {
		.btn-icon {
			font-size: 18px;
		}
	}
}

.btn-prev {
	left: 0;
}

.btn-next {
	right: 0;
}

button.btn-got-it {
	$transitionTime: 0.4s;
	width: 191px;
	min-width: 191px;
	white-space: nowrap;
	transition:
		min-width $transitionTime,
		width $transitionTime,
		opacity $transitionTime,
		padding $transitionTime,
		border-width $transitionTime,
		box-shadow 0.25s,
		color 0.25s;

	:global {
		.btn-icon {
			padding: 0 5px;
		}

		span {
			white-space: nowrap;
		}
	}
}

button.btn-hide {
	width: 0;
	min-width: 0;
	overflow: hidden;
	padding: 0;
	opacity: 0;
	border-width: 0;

	:global {
		span {
			opacity: 0;
			transition: 0.3s;
		}
	}
}
