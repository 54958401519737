@import "../assets/styles/colors";
@import "../assets/styles/media";

.accordion-custom {
	.closed-icon {
		visibility: hidden;
		opacity: 0;
	}

	&.accordion-custom-active {
		.closed-icon {
			visibility: visible;
			opacity: 1;
		}

		.opened-icon {
			visibility: hidden;
			opacity: 0;
		}

		.MuiCollapse-hidden {
			height: auto !important;
			visibility: visible !important;
		}
	}
	.accordion-container {
		margin: 0;
		padding: 0;
		background: #fff;
		border-radius: 10px !important;
		box-shadow: 0px 5px 10px rgba(0, 24, 32, 0.07);

		.accordion-header {
			min-height: 24px;
			display: block;
			margin: 0;
			padding: 16px;

			.accordion-summary-content-wrap {
				display: block;
				margin: 0;

				.accordion-header-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 35px;
					position: relative;

					.opened-closed-icon {
						position: absolute;
						top: 50%;
						right: 0;
						-webkit-transform: translateY(-50%);
						-moz-transform: translateY(-50%);
						-ms-transform: translateY(-50%);
						-o-transform: translateY(-50%);
						transform: translateY(-50%);
						transition: 0.3s;
					}
				}
			}
		}

		.accordion-details {
			display: block;
			margin: 0;
			padding: 0 16px 16px;
			text-align: left;
		}
	}
}
