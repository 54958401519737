@import "../../assets/styles/media";

.personalized-risk-score {
	height: 400px;
	background: var(--background-color-secondary);

	@include desktop-down {
		height: 300px;
	}

	@include desktop-down {
		height: auto;
	}

	.header {
		:global {
			.sub-title {
				margin-top: 37px;

				@include mobile-down {
					margin-top: 30px;
				}
			}
		}
	}

	.content {
		display: flex;
		justify-content: space-between;
		gap: 30px;

		@include mobile-down {
			height: 491px;
			flex-direction: column;
			align-items: center;
			gap: 41px;
		}
	}

	.col-1 {
		max-width: 675px;
		width: 100%;
		padding-top: 162px;

		@include desktop-down {
			padding-top: 50px;
		}

		@include mobile-down {
			max-width: none;
		}
	}

	.col-2 {
		max-width: 370px;
		margin-top: 160px;

		@include desktop-down {
			max-width: 350px;
			margin-top: 90px;
		}

		@include desktop-down {
			margin-top: 50px;
			margin-bottom: 50px;
		}

		@include mobile-down {
			margin-top: 0;
		}
	}
}
